import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import { fadeInVariants, motionContainerProps } from 'animation/variants';
import Button from 'components/buttons';
import StakeCard from 'components/cards/StakeCard';
import Dracula from 'components/icons/Dracula';
import { Icon } from 'components/icons/Icon';
import ClaimModal from 'components/modals/ClaimModal';
import {
  landPlans,
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  lockedLandPlans,
  TOKEN_ID,
} from 'config';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toFixed } from 'utils';
import useStakeContract from 'utils/useStakeContract';

const placeholderNodes = [
  {
    stakeTypeId: 1,
    stakedAmount: 1000 + ' LAND',
    // in milliseconds
    stakeTimestamp: 1648187278000,
    lockingTimestamp: 1123200000,
    unlockTimestamp: 1649310478000,
    // unstakeTimestamp: null,
    // delegationTimestamp: null,
    claimableAmount: 1100 + ' LAND',
    claimable: false,
    unstaked: false,
    // replace 1 with stakeTypeId
    ...landPlans.filter(plan => plan.id === 1)[0],
    id: 1,
    Icon: <Icon name={landPlans.filter(plan => plan.id === 1)[0].title.toLowerCase()} />,
  },
  {
    stakeTypeId: 2,
    stakedAmount: 1000 + ' LAND',
    // in milliseconds
    stakeTimestamp: 1647758207000,
    lockingTimestamp: 172800000,
    unlockTimestamp: 1647931007000,
    unstakeTimestamp: 1647931007000,
    delegationTimestamp: 172800000,
    claimableAmount: 1100 + ' LAND',
    claimable: false,
    unstaked: false,
    // replace 1 with stakeTypeId
    ...landPlans.filter(plan => plan.id === 2)[0],
    id: 2,
    Icon: <Icon name={landPlans.filter(plan => plan.id === 1)[0].title.toLowerCase()} />,
  },
  {
    stakeTypeId: 2,
    stakedAmount: 1000 + ' LAND',
    // in milliseconds
    stakeTimestamp: 1647758207000,
    lockingTimestamp: 172800000,
    unlockTimestamp: 1647931007000,
    unstakeTimestamp: 1647931007000,
    delegationTimestamp: 172800000,
    claimableAmount: 1100 + ' LAND',
    claimable: true,
    unstaked: true,
    // replace 1 with stakeTypeId
    ...landPlans.filter(plan => plan.id === 2)[0],
    id: 3,
    Icon: <Icon name={landPlans.filter(plan => plan.id === 1)[0].title.toLowerCase()} />,
  },
];

const Staked = () => {
  const { isLoggedIn } = useGetLoginInfo();

  const { stakeContract } = useStakeContract(TOKEN_ID);
  const { stakeContract: stakeContractLk1 } = useStakeContract(LKTOKEN_ID1);
  const { stakeContract: stakeContractLk2 } = useStakeContract(LKTOKEN_ID2);
  const { stakeContract: stakeContractMeta } = useStakeContract(LKTOKEN_META_ID);
  const { stakeContract: stakeContractMeta2 } = useStakeContract(LKTOKEN_SECOND_META_ID);

  const { address } = useGetAccountInfo();
  const navigate = useNavigate();

  const [stakedNodes, setStakedNodes] = useState<any>([]);
  const [stakedNodesLk1, setStakedNodesLk1] = useState<any>([]);
  const [stakedNodesLk2, setStakedNodesLk2] = useState<any>([]);
  const [stakedNodesMeta, setStakedNodesMeta] = useState<any>([]);
  const [stakedNodesMeta2, setStakedNodesMeta2] = useState<any>([]);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const mapStakedNodes = (nodes: any, tokenId: string) => {
    if (!nodes) return [];
    return nodes.map((node: any, index: number) => {
      const stakeTypeId = node.stake_type_id.valueOf();
      const days = dayjs.duration(node.locking_timestamp.valueOf(), 's').asDays();
      const flag = node.state.valueOf(); // 1 not unstakeable 2 for claimable

      //TODO parse values for stakedAmount and rewardAmount to be pretty
      const stakedAmount = (node.stake_amount.valueOf() / 10 ** 18).toString();
      const rewardAmount = (node.reward_amount.valueOf() / 10 ** 18).toString();

      const stakeTimestamp = node.stake_timestamp.valueOf();
      const rewardAmountWT =
        flag != 1
          ? parseFloat(rewardAmount).toPrecision(4)
          : (parseFloat(rewardAmount) + parseFloat(rewardAmount) * 0.42).toPrecision(4);
      const delegationTimestamp = node.delegation_timestamp.valueOf();
      const lockingTimestamp = node.locking_timestamp.valueOf();
      const unstakeTimestamp = node.unstake_timestamp.valueOf();
      const landPlan = (tokenId === TOKEN_ID ? landPlans : lockedLandPlans).filter(plan => {
        return plan.days == days;
      })[0];
      const id = node.node_id;

      const apr = node.apy.valueOf();

      return {
        ...landPlan,
        id,
        stakedAmount: stakedAmount + ' ' + tokenId.split('-')[0],
        tokenId,
        stakeTypeId,
        stakeTimestamp,
        rewardAmount: rewardAmountWT,
        claimableAmount: parseInt(stakedAmount) + parseInt(rewardAmountWT) + ' LAND',
        lockingTimestamp,
        claimable: flag == 4,
        unstaked: flag == 3,
        unstakeable: flag == 2,
        unstakeTimestamp,
        delegationTimestamp,
        apr,
        Icon: <Icon name={landPlan.title.toLowerCase()} />,
      };
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (address && stakeContract) {
      stakeContract
        .getNodesPerStaker()
        .then(nodes => {
          setStakedNodes(mapStakedNodes(nodes?.valueOf(), TOKEN_ID));
        })
        .catch(err => console.log(err));
      stakeContractLk1
        ?.getNodesPerStaker()
        .then(nodes => setStakedNodesLk1(mapStakedNodes(nodes?.valueOf(), LKTOKEN_ID1)))
        .catch(err => console.log(err));
      stakeContractLk2
        ?.getNodesPerStaker()
        .then(nodes => setStakedNodesLk2(mapStakedNodes(nodes?.valueOf(), LKTOKEN_ID2)))
        .catch(err => console.log(err));
      stakeContractMeta
        ?.getNodesPerStaker()
        .then(nodes => setStakedNodesMeta(mapStakedNodes(nodes?.valueOf(), LKTOKEN_META_ID)))
        .catch(err => console.log(err));
      stakeContractMeta2
        ?.getNodesPerStaker()
        .then(nodes =>
          setStakedNodesMeta2(mapStakedNodes(nodes?.valueOf(), LKTOKEN_SECOND_META_ID)),
        )
        .catch(err => console.log(err));
    }
  }, [
    address,
    stakeContract,
    stakeContractLk1,
    stakeContractLk2,
    stakeContractMeta,
    stakeContractMeta2,
  ]);

  if (!isLoggedIn) return null;

  const allStakedNodes = [
    ...stakedNodes,
    ...stakedNodesLk1,
    ...stakedNodesLk2,
    ...stakedNodesMeta,
    ...stakedNodesMeta2,
  ];

  return (
    <motion.div className="staked" {...motionContainerProps}>
      <motion.div className="home__title">
        <motion.h1 variants={fadeInVariants}>
          YOUR STAKED <span className="text-purple">LAND</span>
        </motion.h1>
        <motion.p variants={fadeInVariants}>
          Stake your way to fortune in the marketing metaverse.
        </motion.p>
      </motion.div>
      <div className={stakedNodes.length > 0 ? 'staked-grid' : ''}>
        {allStakedNodes.length == 0 && (
          <div className="not-found">
            <Dracula />
            <motion.p className="text-center" variants={fadeInVariants}>
              You don't have any staked LAND.
            </motion.p>
            <div className="max-w-[20rem]">
              <Button className="filled" link="/">
                Stake here
              </Button>
            </div>
          </div>
        )}
        <AnimatePresence>
          {allStakedNodes?.length > 0 &&
            allStakedNodes.map((node: any, index: number) => {
              return (
                <StakeCard
                  key={node.id + ':' + node.tokenId}
                  {...node}
                  handleClaim={() => {
                    setSelectedNode(node);
                    setShowModal(true);
                  }}
                />
              );
            })}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {showModal && (
          <ClaimModal
            {...selectedNode}
            title="Lander"
            onClose={() => {
              setShowModal(false);
              setSelectedNode(null);
            }}
            apr={selectedNode?.apr / 100}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Staked;
