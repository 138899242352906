import { META_CONTRACT_ABI_URL, LKTOKEN_SECOND_META_ID } from './../config';
import { useGetAccountInfo, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import {
  AbiRegistry,
  Address,
  INetworkConfig,
  SmartContract,
  // SmartContractAbi,
} from '@multiversx/sdk-core/out';
import StakeContract from 'api';
import { useEffect, useState } from 'react';
import {
  ABI_URL,
  CONTRACT_ABI_URL,
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  LKTOKEN_META_ID,
  TIMEOUT,
} from '../config';
import { promises } from 'fs';
import axios, { AxiosResponse } from 'axios';
import abi from '../abis/landboard-node-stake.abi.json';
import meta_abi from '../abis/meta-staking.abi.json';

const useStakeContract = (tokenId: string) => {
  const [stakeContract, setStakeContract] = useState<StakeContract | null>(null);
  const { account } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();

  const isMeta = tokenId == LKTOKEN_META_ID || tokenId == LKTOKEN_SECOND_META_ID;

  const loadContract = async () => {
    if (account.address) {
      // @ts-ignore
      const abiRegistry = await AbiRegistry.create(abi);
      // @ts-ignore
      const abiRegistryMeta = await AbiRegistry.create(meta_abi);
      const contract = new SmartContract({
        address: new Address(CONTRACT_ADDRESS[tokenId]),
        abi: !isMeta ? abiRegistry : abiRegistryMeta,
      });
      const provider = new ApiNetworkProvider(network.apiAddress, {
        timeout: TIMEOUT,
      });

      provider
        .getNetworkConfig()
        .then(() => setStakeContract(new StakeContract(account, contract, provider)));
    }
  };

  useEffect(() => {
    loadContract();
  }, [network, account, tokenId]);

  return {
    stakeContract,
  };
};

export default useStakeContract;
