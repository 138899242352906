// import { refreshAccount, transactionServices } from "@multiversx/sdk-dapp";
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { ExtensionProvider } from '@elrondnetwork/erdjs-extension-provider';

import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import {
  Address,
  AddressValue,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  SmartContract,
  TokenPayment,
  Transaction,
  TransactionPayload,
  TransactionWatcher,
} from '@elrondnetwork/erdjs/out';
import {
  ENVIRONMENT,
  liquidityAddress,
  MAIAR_ADDRESS,
  multiPairSwap,
  networkProvider,
  SHARD_0_WRAP,
  SHARD_1_WRAP,
  SHARD_2_WRAP,
  swapTokensFixedInput,
  TOKEN_ID,
  USDC_ID,
  WEGLD_ID,
} from 'config';
import { notifyTransaction } from './transaction';

export const sendTransactionSmart = async (
  account: any,
  swapFromAmount: number,
  swapToAmount: number,
  tokenIdentifier: string,
  decimals: number,
  usdcAmount: number,
  tokenLpAddress: string,
  wegldAmount: number,
  slippage: number,
  isEgld: boolean,
  isWegld: boolean,
  provider: any,
  userShard: number,
  setTransaction: any,
  setTxHash: any,
  setShowModal: any,
) => {
  let contractAddress = new Address(MAIAR_ADDRESS);

  const args: any[] = !(isEgld || isWegld)
    ? [
        BytesValue.fromUTF8(tokenIdentifier),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(tokenIdentifier, swapFromAmount, decimals).valueOf(),
        ),
        BytesValue.fromUTF8(multiPairSwap),
        new AddressValue(new Address(tokenLpAddress)),
        BytesValue.fromUTF8(swapTokensFixedInput),
        BytesValue.fromUTF8(WEGLD_ID),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(
            WEGLD_ID,
            wegldAmount - 0.003 * wegldAmount,
            18,
          ).valueOf(),
        ),
        new AddressValue(
          new Address('erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq'),
        ),
        BytesValue.fromUTF8(swapTokensFixedInput),
        BytesValue.fromUTF8(USDC_ID),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(
            USDC_ID,
            Number(usdcAmount - 0.003 * usdcAmount),
            6,
          ).valueOf(),
        ),
        new AddressValue(new Address(liquidityAddress)),
        BytesValue.fromUTF8(swapTokensFixedInput),
        BytesValue.fromUTF8(TOKEN_ID),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(
            TOKEN_ID,
            swapToAmount - (slippage / 100) * swapToAmount,
            18,
          ).valueOf(),
        ),
      ]
    : [
        BytesValue.fromUTF8(WEGLD_ID),
        new BigUIntValue(TokenPayment.fungibleFromAmount(WEGLD_ID, swapFromAmount, 18).valueOf()),
        BytesValue.fromUTF8(multiPairSwap),
        new AddressValue(
          new Address('erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq'),
        ),
        BytesValue.fromUTF8(swapTokensFixedInput),
        BytesValue.fromUTF8(USDC_ID),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(
            USDC_ID,
            Number(usdcAmount - 0.003 * usdcAmount),
            6,
          ).valueOf(),
        ),
        new AddressValue(new Address(liquidityAddress)),
        BytesValue.fromUTF8(swapTokensFixedInput),
        BytesValue.fromUTF8(TOKEN_ID),
        new BigUIntValue(
          TokenPayment.fungibleFromAmount(
            TOKEN_ID,
            Number(swapToAmount - (slippage / 100) * swapToAmount),
            18,
          ).valueOf(),
        ),
      ];

  const payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction('ESDTTransfer'))
    .setArgs(args)
    .build();

  let tx = new Transaction({
    receiver: contractAddress,
    gasLimit: 300000000,
    data: payload,
    chainID: ENVIRONMENT == 'mainnet' ? '1' : 'D',
  });

  let wrapTx = new Transaction({
    data: new TransactionPayload('wrapEgld'),
    gasLimit: 10000000,
    receiver: new Address(
      userShard == 0 ? SHARD_0_WRAP : userShard == 1 ? SHARD_1_WRAP : SHARD_2_WRAP,
    ),
    value: TokenPayment.egldFromAmount(swapFromAmount),
    chainID: ENVIRONMENT == 'mainnet' ? '1' : 'D',
  });

  await refreshAccount();
  if (isEgld) {
    wrapTx.setNonce(account.nonce);
    tx.setNonce(account.nonce + 1);
  } else {
    tx.setNonce(account.nonce);
  }

  setShowModal(true);
  let signature = await provider.signTransactions(isEgld ? [wrapTx, tx] : [tx]);

  let transaction = await networkProvider.sendTransaction(signature[0]);
  let transaction2 = isEgld && (await networkProvider.sendTransaction(signature[1]));

  // signature.map(async (tx: any) => {
  //   let transaction = await networkProvider.sendTransaction(tx);
  //   notifyTransaction({
  //     ...signature[0],
  //     status: "pending",
  //     hash: transaction,
  //   });
  //   await 5000;
  // });
  notifyTransaction({
    ...signature[0],
    status: 'pending',
    hash: transaction,
  });

  isEgld ? setTxHash([transaction, transaction2]) : setTxHash(transaction);

  isEgld &&
    notifyTransaction({
      ...signature[1],
      status: 'pending',
      hash: transaction2,
    });

  setTransaction(signature);
  // check if transaction is finished
};

export const sendTransactionUSDC = async (
  account: any,
  swapFromAmount: number,
  swapToAmount: number,
  tokenIdentifier: string,
  decimals: number,
  provider: any,
) => {
  let contractAddress = new Address(MAIAR_ADDRESS);
  let contract = new SmartContract({ address: contractAddress });
  let addressOfSender = new Address(account.address);

  const args: any[] = [
    BytesValue.fromUTF8(tokenIdentifier),
    new BigUIntValue(
      TokenPayment.fungibleFromAmount(tokenIdentifier, swapFromAmount, decimals).valueOf(),
    ),
    BytesValue.fromUTF8(swapTokensFixedInput),
    BytesValue.fromUTF8(TOKEN_ID),
    new BigUIntValue(TokenPayment.fungibleFromAmount(TOKEN_ID, swapToAmount, 18).valueOf()),
  ];

  const payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction('ESDTTransfer'))
    .setArgs(args)
    .build();

  let tx = new Transaction({
    receiver: new Address(liquidityAddress),
    gasLimit: 50000000,
    data: payload,
    chainID: ENVIRONMENT == 'mainnet' ? '1' : 'D',
  });

  tx.setNonce(account.nonce);

  await refreshAccount();

  let signature = await provider.signTransactions([tx]);

  const transaction = await networkProvider.sendTransaction(signature[0]);
  notifyTransaction({ ...tx, status: 'pending', hash: transaction });
};
