import {
  defaultMotionContainerProps,
  motionContainerProps,
  scaleFadeInVariants,
} from 'animation/variants';
import Button from 'components/buttons';
import { Icon as LBIcon } from 'components/icons/Icon';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { ReactNode, useMemo } from 'react';
import useTimeUntil from 'utils/useTimeUntil';

interface StakeCardProps {
  id: number;
  stakeTypeId: number;
  stakedAmount: string;
  stakeTimestamp: string;
  lockingTimestamp?: string;
  claimableAmount: string;
  rewardAmount: string;
  claimable: boolean;
  unstakeable: boolean;
  unstaked: boolean;
  tokenId: string;
  unstakeTimestamp?: string;
  delegationTimestamp?: string;
  title: string;
  days: number;
  Icon?: ReactNode;
  handleClaim?: () => void;
}

const StakeCard = ({
  title,
  days,
  Icon,
  handleClaim,
  stakedAmount,
  rewardAmount,
  stakeTimestamp,
  lockingTimestamp,
  claimable,
  unstakeable,
  unstaked,
  unstakeTimestamp,
  delegationTimestamp,
}: StakeCardProps) => {
  const { dateUntil, startDate, endDate } = useMemo(() => {
    let dateUntil = Date.now();
    let startDate = Date.now();
    let endDate = Date.now();

    if ((claimable || unstaked) && unstakeTimestamp && delegationTimestamp) {
      startDate = parseInt(unstakeTimestamp);
      endDate = parseInt(unstakeTimestamp) + parseInt(delegationTimestamp);
    } else if (stakeTimestamp && lockingTimestamp) {
      startDate = parseInt(stakeTimestamp);
      endDate = parseInt(stakeTimestamp) + parseInt(lockingTimestamp);
    }

    dateUntil = endDate;

    return { startDate, endDate, dateUntil };
  }, [claimable, delegationTimestamp, lockingTimestamp, stakeTimestamp, unstakeTimestamp]);

  const { timeLeft, days: d, hours, minutes, seconds } = useTimeUntil(dateUntil * 1000);

  return (
    <motion.div {...motionContainerProps} variants={scaleFadeInVariants} className="stake-card">
      <div className="stake-card__hero">
        <img src={`/assets/images/${title.toLowerCase()}.png`} alt="" />
        {Icon && Icon}
        <h2>{title}</h2>
        <span>{days} days</span>
      </div>
      <div className="stake-card__body">
        <ul>
          <li>
            <LBIcon name="calendar_star" primary />
            {dayjs.unix(startDate).format('DD-MM-YYYY')} -{' '}
            {dayjs.unix(endDate).format('DD-MM-YYYY')}
          </li>
          <li>
            <LBIcon name="money" primary />
            staked {stakedAmount}
          </li>
          <li>
            <hr />
          </li>
          <li>
            <LBIcon name="gift" primary />
            {rewardAmount} LAND
          </li>
        </ul>
        <div className="stake-card__actions">
          <Button
            className="uppercase filled"
            disabled={timeLeft > 0}
            onClick={handleClaim}
            hideComingSoon
          >
            {(!unstaked || unstakeable) && 'Unstake '}
            {(claimable || unstaked) && 'Claim '}
            {timeLeft > 0 && d + 'd : ' + hours + 'h : ' + minutes + 'm : ' + seconds + 's'}
          </Button>
          {timeLeft > 0 && (
            <Button className="uppercase outline" onClick={handleClaim} hideComingSoon>
              Info
            </Button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default StakeCard;
