import Button from 'components/buttons';
import Dracula from 'components/icons/Dracula';
import React from 'react';

const Maintenance = ({ onClose }: any) => {
  return (
    <div className="p-12" style={{ padding: 50 }}>
      <h3>Claiming is currently under maintenance.</h3>{' '}
      <div style={{ margin: '20px 0' }}>
        <Dracula />
      </div>
      <p>We're coming back live soon. All your funds are safe!</p>
      <Button className="mt-5 filled" onClick={onClose} hideAnimate>
        Close
      </Button>
    </div>
  );
};

export default Maintenance;
