import {
  useTrackTransactionStatus,
  useGetAccountInfo,
} from "@multiversx/sdk-dapp/hooks";
import UnlockContract from "api/unlock";

import axios from "axios";
import Button from "components/buttons";
import {
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  TOKEN_ID,
} from "config";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  phaseAtom,
  presaleSelectedAtom,
  sessionIdAtom,
  tokensAtom,
  transactionStatusAtom,
} from "store/atoms";
import useTimeUntil from "utils/useTimeUntil";
import useUnlockContract from "utils/useUnlockContract";
// import UnlockTest from './UnlockTest';
import useGetBalance from "../../hooks/useGetBalance";
import { BigNumber } from "bignumber.js";
import { notifyError, notifyLoading, notifySuccess } from "utils/notifications";
import MigrateButton from "components/buttons/unlock/MigrateButton";
import ClaimButton from "components/buttons/unlock/ClaimButton";

const Actions = ({ onClose }: any) => {
  const { metaBalance, phase, nonce } = useGetBalance(1);
  const { metaBalance: metaBalanceSecond, nonce: nonceSecond } =
    useGetBalance(2);

  const { address, account, ...rest } = useGetAccountInfo();
  const { unlockContract } = useUnlockContract("a");

  const [hasShownPending, setHasShownPending] = useState(false);
  const [needsMigration, setNeedsMigration] = useState(false);

  const [sessionId, setSessionId] = useAtom(sessionIdAtom);
  const [, setTransactionStatus] = useAtom(transactionStatusAtom);
  const [tokens, setTokens] = useAtom(tokensAtom);

  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      notifySuccess("Transaction successful");
    },
    onFail: () => {
      notifyError("Transaction failed");
    },
  });

  // useEffect(() => {
  //   setTransactionStatus(transactionStatus);
  // }, []);

  useEffect(() => {
    if (
      !hasShownPending &&
      transactionStatus.isPending &&
      transactionStatus.transactions
    ) {
      notifyLoading(
        "Transaction pending",
        // @ts-ignore
        transactionStatus.transactions[0].hash
      );
      setHasShownPending(true);
    }

    // @ts-ignore
    // console.log(transactionStatus.transactions[0].hash);
  }, [transactionStatus]);

  const getAttributes = async () => {
    const data = await unlockContract?.getAttributes();
    console.log("unlockContract", unlockContract);

    console.log("data", data);
    data?.valueOf().unlock_milestones.map((item: any) =>
      console.log("attributes", {
        epoch: item.unlock_epoch.valueOf(),
        idk: item.unlock_percent.valueOf(),
      })
    );
  };

  // getAttributes();

  useEffect(() => {
    metaBalance < 0.0001 &&
      metaBalanceSecond < 0.0001 &&
      setNeedsMigration(true);
  }, [metaBalance, metaBalanceSecond]);

  return (
    <div>
      {" "}
      <div className="lboard-modal__actions">
        <div className="relative flex items-center gap-3">
          {metaBalance > 0.001 || metaBalanceSecond > 0.001 ? (
            <>
              {metaBalance > 0.001 && (
                <ClaimButton
                  presale={1}
                  balance={metaBalance}
                  phase={phase}
                  transactionStatus={transactionStatus}
                  nonce={nonce}
                />
              )}
              {metaBalanceSecond > 0.001 && (
                <ClaimButton
                  presale={2}
                  balance={metaBalanceSecond}
                  phase={phase}
                  transactionStatus={transactionStatus}
                  nonce={nonceSecond}
                />
              )}
            </>
          ) : (
            <>
              <MigrateButton
                presale={1}
                transactionStatus={transactionStatus}
              />
              {Number((tokens[LKTOKEN_ID1].balance / 10 ** 18).toFixed(4)) >
                0 && (
                <MigrateButton
                  presale={2}
                  transactionStatus={transactionStatus}
                />
              )}
            </>
          )}
        </div>
        <Button className="text" onClick={onClose} hideAnimate>
          Cancel
        </Button>
      </div>
      {/* <UnlockTest /> */}
    </div>
  );
};

export default Actions;
