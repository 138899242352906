import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { ExtensionProvider } from '@elrondnetwork/erdjs-extension-provider';

import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import {
  Account,
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  ESDTTransferPayloadBuilder,
  Interaction,
  ResultsParser,
  SmartContract,
  TokenPayment,
  Transaction,
  TransactionPayload,
  TransactionWatcher,
  TypedValue,
  U64Value,
} from '@elrondnetwork/erdjs/out';
import {
  chainID,
  LOCK_CONTRACT,
  SECOND_UNLOCK_CONTRACT,
  SWITCH_TOKENS,
  UNLOCK_ASSETS,
  UNLOCK_CONTRACT,
} from 'config';
import BigNumber from 'bignumber.js';

export default class UnlockContract {
  contract: SmartContract;
  stakerAddress: Address;
  stakerAccount: Account;
  provider: ApiNetworkProvider;

  constructor(account: any, contract: SmartContract, provider: ApiNetworkProvider) {
    this.stakerAddress = account.address;
    this.stakerAccount = account;
    this.contract = contract;
    this.provider = provider;
  }

  createLockTransaction = async (
    account: any,
    tokenId: string,
    amount: number = 100,
    isFirst: boolean,
  ) => {
    const args: TypedValue[] = [
      BytesValue.fromUTF8(tokenId),
      new BigUIntValue(new BigNumber(amount)),
      BytesValue.fromUTF8(isFirst ? 'switchTokensPresale1' : 'switchTokensPresale2'),
    ];

    let { argumentsString } = new ArgSerializer().valuesToString(args);
    argumentsString = argumentsString.toUpperCase();
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

    let tx = new Transaction({
      receiver: new Address(LOCK_CONTRACT),
      gasLimit: 100000000,
      data: data,
      chainID: chainID,
    });
    tx.setNonce(account.nonce);
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;

    // console.log('hash', hash);

    // notifyTransaction({ ...tx, status: "pending" });
  };

  createUnlockTransaction = async (
    account: any,
    tokenId: string,
    nonce: number,
    amount: number = 100,
    isFirst: boolean,
  ) => {
    const args: TypedValue[] = [
      BytesValue.fromUTF8(tokenId),
      new BigUIntValue(nonce),
      new BigUIntValue(TokenPayment.egldFromAmount(amount).valueOf()),
      new AddressValue(new Address(isFirst ? UNLOCK_CONTRACT : SECOND_UNLOCK_CONTRACT)),
      BytesValue.fromUTF8(UNLOCK_ASSETS),
    ];

    let { argumentsString } = new ArgSerializer().valuesToString(args);
    argumentsString = argumentsString.toUpperCase();
    const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

    let tx = new Transaction({
      receiver: new Address(account.address),
      gasLimit: 20000000,
      data: data,
      chainID: chainID,
    });
    tx.setNonce(account.nonce);
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
  };

  createSwitchMetaTransaction = async (
    account: any,
    tokenId: string,
    nonce: number,
    amount: number = 100,
  ) => {
    const args: TypedValue[] = [
      BytesValue.fromUTF8(tokenId),
      new BigUIntValue(nonce),
      new BigUIntValue(TokenPayment.egldFromAmount(amount).valueOf()),
      new AddressValue(new Address(LOCK_CONTRACT)),
      BytesValue.fromUTF8('switchExistingPresale1'),
    ];

    let { argumentsString } = new ArgSerializer().valuesToString(args);
    argumentsString = argumentsString.toUpperCase();
    const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

    let tx = new Transaction({
      receiver: new Address(account.address),
      gasLimit: 20000000,
      data: data,
      chainID: chainID,
    });
    tx.setNonce(account.nonce);
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
  };

  getAttributes = async () => {
    const args = [new U64Value(5)];
    const interaction: Interaction = this.contract.methodsExplicit.getUnlockScheduleForSFTNonce(
      args,
    );
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );
    return firstValue;
  };

  // notifyTransaction({ ...tx, status: "pending" });
}
