import BuyTokenCard from 'components/cards/BuyTokenCard';

const BuyTokens = () => {
  return (
    <div className="buy-tokens-container">
      <BuyTokenCard />
    </div>
  );
};

export default BuyTokens;
