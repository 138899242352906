export const delay = 0.1;

export const defaultMotionContainerProps = {
  initial: 'hidden',
  whileInView: 'visible',
  viewport: { once: true },
};

export const motionSlideUpContainerProps = {
  ...defaultMotionContainerProps,
  variants: {
    hidden: {
      y: '100%',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delayChildren: delay,
        staggerChildren: delay,
      },
    },
  },
};

export const motionContainerProps = {
  ...defaultMotionContainerProps,
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: delay,
        staggerChildren: delay,
      },
    },
  },
};

export const fadeInVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const scaleFadeInVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: delay,
    },
  },
};

export const scaleInVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
  },
};

export const slideUpMenuVariants = {
  expanded: {
    y: '20px',
  },
  collapsed: {
    y: '320px',
  },
};

export const mobileMenuVariants = {
  hidden: {
    x: '100%',
    display: 'grid',
  },
  visible: {
    x: '0',
    display: 'grid',
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export const copyButtonVariants = {
  inactive: {
    fill: '#ffffff',
  },
  active: {
    fill: '#8E2DE2',
  },
};
