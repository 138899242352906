import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  BLAST_API,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  PRESALE_1_UNLOCK_START,
  PRESALE_2_UNLOCK_START,
} from "config";
import axios from "axios";
import {
  useGetAccountInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks";
import { useAtom } from "jotai";
import { phaseAtom } from "store/atoms";

const useGetBalance = (presale: number) => {
  const { network } = useGetNetworkConfig();
  const { address, account, ...rest } = useGetAccountInfo();

  const phasesNonce = {
    // nonce: phase
    1: 1,
    2: 2,
    3: 1,
    4: 2,
  };

  const [allPhasesBalance, setAllPhasesBalance] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    17: 0,
    18: 0,
    19: 0,
    20: 0,
    21: 0,
    22: 0,
    23: 0,
    24: 0,
  });
  const [metaBalance, setMetaBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [nonce, setNonce] = useState(0);

  const [phase, setPhase] = useState(0);

  dayjs.extend(relativeTime);

  let unlockStart = dayjs(
    presale == 1 ? PRESALE_1_UNLOCK_START : PRESALE_2_UNLOCK_START
  );
  const currentStage = presale == 1 ? 3 : 4;
  // let currentStage = 2;

  useEffect(() => {
    setPhase(currentStage);
  }, [currentStage]);

  useEffect(() => {
    // set nonce to the index of the biggest of all phases balance
    const maxPhase = Object.keys(allPhasesBalance).reduce((a, b) =>
      // @ts-ignore
      allPhasesBalance[a] > allPhasesBalance[b] ? a : b
    );

    // @ts-ignore
    setNonce(Number(maxPhase));
  }, [allPhasesBalance]);

  useEffect(() => {
    if (currentStage > 0) {
      for (let i = 0; i < 25; i++) {
        getBalance(i);
      }
    }
  }, [currentStage]);

  useEffect(() => {
    setMetaBalance(
      //  select the biggest of all phases balance
      Math.max(...Object.values(allPhasesBalance))
    );
  }, [allPhasesBalance, currentStage]);

  const getBalance = (stage: number) => {
    axios
      .get(
        `${BLAST_API}/accounts/${address}/nfts/${
          presale == 1 ? LKTOKEN_META_ID : LKTOKEN_SECOND_META_ID
        }-${
          stage == 9
            ? "0a"
            : stage == 10
            ? "0b"
            : stage == 11
            ? "0c"
            : stage == 12
            ? "0d"
            : stage == 13
            ? "0e"
            : stage == 14
            ? "0f"
            : stage == 15
            ? "10"
            : stage == 16
            ? "11"
            : stage == 17
            ? "12"
            : stage == 18
            ? "13"
            : stage == 19
            ? "14"
            : stage == 20
            ? "15"
            : stage == 21
            ? "16"
            : stage == 22
            ? "17"
            : stage == 23
            ? "18"
            : stage == 24
            ? "19"
            : `${`0${stage + 1}`}`
        }`
      )
      .then((res) => {
        const balance = res.data.balance / 10 ** 18;

        // migrationStep.push(true);
        setAllPhasesBalance((prevState) => ({
          ...prevState,
          [stage + 1]: balance,
        }));
      })
      .catch((err) => {
        // migrationStep.push(true);
      });
  };

  useEffect(() => {
    let total = 0;
    for (let i = 1; i < 25; i++) {
      // @ts-ignore
      total += allPhasesBalance[i];
    }

    setTotalBalance(total);
  }, [allPhasesBalance]);

  // console.log(allPhasesBalance);

  return { metaBalance, allPhasesBalance, totalBalance, phase, nonce };
};

export default useGetBalance;
