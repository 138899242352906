import * as DappUI from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import NavBar from 'components/navbar';
import { ENVIRONMENT, walletConnectV2ProjectId } from 'config';
import NotFound from 'pages/404';
import Home from 'pages/Home';
import React, { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import 'rc-tooltip/assets/bootstrap_white.css';
import Unlock from 'pages/Unlock';

const {
  SignTransactionsModals,
  NotificationModal,
  DappCorePages: { UnlockPage },
} = DappUI;

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={ENVIRONMENT}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout: 6000,
          walletConnectV2ProjectId: walletConnectV2ProjectId,
        }}
        // completedTransactionsDelay={200}
      >
        <Fragment>
          <NavBar />
          <main className="container">
            <NotificationModal />
            <SignTransactionsModals className="modal-card" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={routeNames.unlock} element={<Unlock />} />
              {routes.map((route: any, index: number) => (
                <Route path={route.path} key={'route-key-' + index} element={<route.component />} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Toaster
              toastOptions={{
                className: 'z-[10000]',
                position: 'bottom-right',
                duration: 5000,
              }}
            />
          </main>
        </Fragment>
      </DappProvider>
    </Router>
  );
};

export default App;
