import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { motionContainerProps, scaleFadeInVariants } from 'animation/variants';
import Button from 'components/buttons';
import { Icon as LBIcon } from 'components/icons/Icon';
import {
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  TOKEN_ID,
} from 'config';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { sessionIdAtom } from 'store/atoms';
import { notifyError, notifyLoading, notifySuccess } from 'utils/notifications';
import useStakeContract from 'utils/useStakeContract';
import useTimeUntil from 'utils/useTimeUntil';

interface ClaimModalProps {
  id: number;
  stakeTypeId: number;
  stakedAmount: string;
  stakeTimestamp: string;
  lockingTimestamp: string;
  claimableAmount: string;
  rewardAmount: string;
  claimable: boolean;
  unstaked: boolean;
  unstakeable: boolean;
  unstakeTimestamp: string;
  delegationTimestamp: string;
  tokenId: string;
  title: string;
  apr: number;
  Icon?: ReactNode;
  handleClaim?: () => void;
  onClose: () => void;
}
const ClaimModal = ({
  id,
  tokenId,
  title,
  Icon,
  stakedAmount,
  stakeTypeId,
  stakeTimestamp,
  lockingTimestamp,
  claimableAmount,
  unstakeable,
  claimable,
  rewardAmount,
  unstaked,
  unstakeTimestamp,
  delegationTimestamp,
  onClose,
  apr,
}: ClaimModalProps) => {
  const {
    dateUntil,
    stakeDate,
    unlockDate,
    unstakeDate,
    claimDate,
    undelegateDate,
  } = useMemo(() => {
    let dateUntil: string | number = Date.now();
    let startDate: string | number = Date.now();
    let endDate: string | number = Date.now();
    let undelegateDate = unstaked
      ? parseInt(unstakeTimestamp) + parseInt(delegationTimestamp)
      : null;
    let stakeDate = parseInt(stakeTimestamp);
    let unlockDate = parseInt(stakeTimestamp) + parseInt(lockingTimestamp);
    let unstakeDate = parseInt(unstakeTimestamp);
    let claimDate = unstakeTimestamp
      ? parseInt(unstakeTimestamp) + parseInt(delegationTimestamp)
      : null;

    if ((claimable || unstaked) && unstakeTimestamp && delegationTimestamp) {
      startDate = parseInt(unstakeTimestamp);
      endDate = parseInt(unstakeTimestamp) + parseInt(delegationTimestamp);
    } else if (stakeTimestamp && lockingTimestamp) {
      startDate = parseInt(stakeTimestamp);
      endDate = parseInt(stakeTimestamp) + parseInt(lockingTimestamp);
    }

    dateUntil = endDate;

    return {
      startDate,
      endDate,
      dateUntil,
      stakeDate,
      unstakeDate,
      claimDate,
      unlockDate,
      undelegateDate,
    };
  }, [claimable, delegationTimestamp, lockingTimestamp, stakeTimestamp, unstakeTimestamp]);

  const { stakeContract } = useStakeContract(TOKEN_ID);
  const { stakeContract: stakeContractLk1 } = useStakeContract(LKTOKEN_ID1);
  const { stakeContract: stakeContractLk2 } = useStakeContract(LKTOKEN_ID2);
  const { stakeContract: stakeContractMeta } = useStakeContract(LKTOKEN_META_ID);
  const { stakeContract: stakeContractMeta2 } = useStakeContract(LKTOKEN_SECOND_META_ID);

  const { timeLeft, days: d, hours, minutes, seconds } = useTimeUntil(dateUntil * 1000);

  const [hasShownPending, setHasShownPending] = useState(false);
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);

  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      notifySuccess('Transaction successful');
    },
    onFail: () => {
      notifyError('Transaction failed');
    },
  });

  useEffect(() => {
    if (!hasShownPending && transactionStatus.isPending && transactionStatus.transactions) {
      notifyLoading(
        'Transaction pending',
        // @ts-ignore
        transactionStatus.transactions[0].hash,
      );
      setHasShownPending(true);
    }
  }, [transactionStatus]);

  const handleClick = async () => {
    const contract =
      tokenId === TOKEN_ID
        ? stakeContract
        : tokenId === LKTOKEN_ID1
        ? stakeContractLk1
        : tokenId === LKTOKEN_META_ID
        ? stakeContractMeta
        : tokenId === LKTOKEN_SECOND_META_ID
        ? stakeContractMeta2
        : stakeContractLk2;

    let sessionId;

    if (claimable) {
      sessionId = await contract?.createClaimTransaction(id);
      toast('Sending claim transaction.');
    } else {
      sessionId = await contract?.createUnstakeTransaction(id);
      toast('Sending unstake transaction.');
    }

    setSessionId(sessionId);
    onClose();
  };

  return (
    <div className="lboard-modal">
      <motion.div
        className="lboard-modal__content"
        {...motionContainerProps}
        variants={scaleFadeInVariants}
      >
        <div className="lboard-modal__header">
          {title && <img src={`/assets/images/${title.toLowerCase()}.png`} alt="" />}
          {Icon && Icon}
          <h2>{title}</h2>
        </div>
        <div className="lboard-modal__body">
          <ul>
            <li>
              <span className="lboard-modal__label">
                <LBIcon name="money" primary />
                Staked
              </span>
              <span>{stakedAmount}</span>
            </li>
            <li>
              <span className="lboard-modal__label">
                <LBIcon name="gift" primary />
                Rewards
              </span>
              <span> {rewardAmount} LAND</span>
            </li>
            <li>
              <span className="lboard-modal__label">
                <LBIcon name="calendar_check" primary />
                Stake Time
              </span>
              <span>{dayjs.unix(stakeDate).format('MMM DD, YYYY hh:mm A')}</span>
            </li>
            <li>
              <span className="lboard-modal__label">
                <LBIcon name="calendar_star" primary />
                Unlock Time
              </span>
              <span> {dayjs.unix(unlockDate).format('MMM DD, YYYY hh:mm A')}</span>
            </li>
            {undelegateDate && (
              <li>
                <span className="lboard-modal__label">
                  <LBIcon name="calendar_star" primary />
                  Delegate Time
                </span>
                <span> {dayjs.unix(undelegateDate).format('MMM DD, YYYY hh:mm A')}</span>
              </li>
            )}
            {unstaked && (
              <li>
                <span className="lboard-modal__label">
                  <LBIcon name="calendar_star" primary />
                  Unstake Time
                </span>
                <span> {dayjs.unix(unstakeDate).format('MMM DD, YYYY hh:mm A')}</span>
              </li>
            )}
            {unstaked && claimDate && dayjs(claimDate).isValid() && (
              <li>
                <span className="lboard-modal__label">
                  <LBIcon name="calendar_star" primary />
                  Claim Time
                </span>
                <span> {dayjs.unix(claimDate).format('MMM DD, YYYY hh:mm A')}</span>
              </li>
            )}
            <li>
              <span className="lboard-modal__label">
                <LBIcon name="percentage" primary />
                APR
              </span>
              <span>{apr}%</span>
            </li>
          </ul>
          {!(claimable || unstakeable) && (
            <motion.div className="home__form--info">
              <LBIcon name="info" primary />
              <span>
                Unstaking before end period only gives 70% of rewards + 5 days of undelegation time.
              </span>
            </motion.div>
          )}

          <div className="lboard-modal__actions">
            <Button
              className="filled"
              onClick={handleClick}
              disabled={timeLeft > 0 && unstaked}
              hideAnimate
              hideComingSoon
            >
              {(!unstaked || unstakeable) && 'Unstake '}
              {(claimable || unstaked) && 'Claim '}
            </Button>
            <Button className="text" onClick={onClose} hideAnimate>
              Cancel
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ClaimModal;
