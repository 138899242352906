import UnlockContract from 'api/unlock';
import { META_CONTRACT_ABI_URL, LKTOKEN_SECOND_META_ID, UNLOCK_CONTRACT } from './../config';
import { useGetAccountInfo, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import {
  AbiRegistry,
  Address,
  INetworkConfig,
  SmartContract,
  SmartContractAbi,
} from '@elrondnetwork/erdjs/out';
import { useEffect, useState } from 'react';
import {
  ABI_URL,
  CONTRACT_ABI_URL,
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  LKTOKEN_META_ID,
  TIMEOUT,
} from '../config';
import { promises } from 'fs';
import axios, { AxiosResponse } from 'axios';
import abi from '../abis/unlock.abi.json';
import meta_abi from '../abis/meta-staking.abi.json';

const useUnlockContract = (tokenId: string) => {
  const [unlockContract, setUnlockContract] = useState<UnlockContract | null>(null);
  const { account } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();

  const loadContract = async () => {
    if (account.address) {
      // @ts-ignore
      const abiRegistry = await AbiRegistry.create(abi);
      // @ts-ignore

      const contract = new SmartContract({
        address: new Address(UNLOCK_CONTRACT),
        abi: new SmartContractAbi(abiRegistry, [CONTRACT_NAME]),
      });
      const provider = new ApiNetworkProvider(network.apiAddress, {
        timeout: TIMEOUT,
      });

      provider
        .getNetworkConfig()
        .then(() => setUnlockContract(new UnlockContract(account, contract, provider)));
    }
  };

  useEffect(() => {
    loadContract();
  }, [network, account, tokenId]);

  return {
    unlockContract,
  };
};

export default useUnlockContract;
