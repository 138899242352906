import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
// import { TokenPayment } from "@elrondnetwork/erdjs/out";
import axios from 'axios';
import Button from 'components/buttons';
import Input from 'components/input';
import { CONTRACT_ADDRESS, ENVIRONMENT, MAIAR_ADDRESS } from 'config';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
// import { transactionsAtom } from "store/atoms";
import { notifyLoading } from 'utils/notifications';

const variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const conversionRate = 0.0003;
const contractAddress = CONTRACT_ADDRESS;
const environment = ENVIRONMENT === 'mainnet' ? '' : 'devnet' + '-';

export const LabelButton = (props: any) => (
  <button
    type="button"
    className="flex px-2 py-1 text-sm font-bold uppercase rounded bg-purple-darker text-purple"
    {...props}
  >
    Max
  </button>
);

const BuyTokenCard = () => {
  const { address, account, ...rest } = useGetAccountInfo();

  const [totalLandBalance, setTotalLandBalance] = useState(0);
  const [egldPrice, setEgldPrice] = useState(0);
  const [egldAmount, setEgldAmount] = useState('0');
  const [landAmount, setLandAmount] = useState('0');
  //   const [transactions, setTransactions] = useAtom(transactionsAtom);

  //   const { timeLeft, hours, minutes, seconds } = useTimeUntil(1652119200000);

  const handleChangeEgldAmount = (e: any) => {
    const regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);

    if ((test_result && e.target.value <= 1) || e.target.value == '') {
      setEgldAmount(e.target.value);
      setLandAmount((e.target.value / conversionRate).toFixed(4));
    }
  };

  const handleChangeLandAmount = (e: any) => {
    const regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);

    if ((test_result && e.target.value <= 3333.3333) || e.target.value == '') {
      setLandAmount(e.target.value);
      setEgldAmount((e.target.value * conversionRate).toFixed(4));
    }
  };

  //   const buyToken = async (e: any) => {
  //     e.preventDefault();
  //     const tx = {
  //       value: TokenPayment.egldFromAmount(egldAmount),
  //       data: "buy",
  //       receiver: contractAddress,
  //     };
  //     await refreshAccount();
  //     const { sessionId } = await sendTransactions({
  //       transactions: tx,
  //     });
  //     if (sessionId) {
  //       notifyLoading("You are buying " + landAmount + " LAND");
  //       setTransactions([
  //         ...transactions,
  //         {
  //           sessionId,
  //           status: "pending",
  //           type: "specific",
  //         },
  //       ]);
  //     }
  //   };

  useEffect(() => {
    axios.get(`https://${environment}api.elrond.com/economics`).then((res: any) => {
      setEgldPrice(res.data.price);
    });
  }, []);

  useEffect(() => {
    if (account.address != '') {
      axios
        .get(`https://${environment}api.elrond.com/accounts/${account.address}/tokens`)
        .then((res: any) => {
          if (res.data?.length > 0) {
            const tokens = res.data.filter(
              (a: any) => a?.identifier === 'LAND-40f26f' || a?.ticker === 'LAND-40f26f',
            );
            setTotalLandBalance(tokens.length > 0 ? tokens[0].balance / 10 ** 18 : 0);
          }
        });
    }
  }, [account]);

  const totalEgldBalance = useMemo(
    () => (account ? parseInt(account.balance) / 10 ** 18 : 0).toFixed(4),
    [account],
  );
  const disabled =
    egldAmount === '0' || landAmount === '0' || !egldAmount || !landAmount || !address;
  // timeLeft > 0;

  const accountEgldBalance = parseFloat(totalEgldBalance);

  const handleSendTransaction = (e: any) => {
    e.preventDefault();

    // sendTransactionUSDC(account);
  };

  return (
    <motion.div variants={variants} className="buy-token-card">
      <h2 className="mb-10">Buy LAND Token</h2>
      <form className="flex flex-col gap-5 text-left" onSubmit={() => console.log('sending tx')}>
        <Input label="YOU BUY" value={landAmount} onChange={handleChangeLandAmount} />
        {address && <span className="tiny-label">You have {totalLandBalance} LAND</span>}
        <Input
          label="YOU PAY"
          value={egldAmount}
          onChange={handleChangeEgldAmount}
          LabelButton={
            <LabelButton
              onClick={() =>
                handleChangeEgldAmount({
                  target: {
                    value:
                      accountEgldBalance < 1
                        ? accountEgldBalance > 0.0075
                          ? (accountEgldBalance - 0.0075).toFixed(4)
                          : 0
                        : 1,
                  },
                })
              }
            />
          }
        />
        {address && (
          <p className="tiny-label">
            <span>Minimum buy 0.2 $EGLD</span>
            <span>You have {totalEgldBalance} EGLD</span>
            <span>
              You will receive 20% unlocked and 80% locked tokens (
              <a href="https://twitter.com/landboard_io/status/1494239023094767619?s=21">
                see vesting period here
              </a>
              )
            </span>
          </p>
        )}
        <Button
          className="w-full filled"
          containerClassname="mt-5"
          type="submit"
          hideComingSoon
          disabled={disabled}
          onClick={(e: any) => handleSendTransaction(e)}
        >
          BUY $LAND
        </Button>
        <span className="text-xs font-bold">1 EGLD= ${egldPrice} | 1 EGLD = 3333 LAND</span>
      </form>
    </motion.div>
  );
};

export default BuyTokenCard;
