export const getEquivalent = (
  setExchangeRate: any,
  landPair: any,
  amount: number,
  isReversed: boolean,
) => {
  if (!(Object.keys(landPair).length === 0) && amount > 0) {
    const {
      reserves0: landReserves,
      reserves1: usdcReserves,
      // @ts-ignore
    } = landPair?.info;

    const realReserves = {
      landReserves: landReserves / 10 ** 18,
      usdcReserves: usdcReserves / 10 ** 6,
    };

    const total = realReserves.landReserves * realReserves.usdcReserves;

    const newReserves = !isReversed
      ? {
          landReserves: total / (realReserves.usdcReserves + amount),
          usdcReserves: realReserves.usdcReserves + amount,
        }
      : {
          landReserves: realReserves.landReserves + amount,
          usdcReserves: total / (realReserves.landReserves + amount),
        };

    const exchangeRate = newReserves.usdcReserves / newReserves.landReserves;
    //   round to 5 decimals
    setExchangeRate(Number(parseFloat(exchangeRate.toString()).toFixed(5)));

    const equivalent = !isReversed
      ? realReserves.landReserves -
        newReserves.landReserves -
        0.01 * (realReserves.landReserves - newReserves.landReserves)
      : realReserves.usdcReserves -
        newReserves.usdcReserves -
        0.01 * (realReserves.usdcReserves - newReserves.usdcReserves);

    return equivalent;
  } else {
    return 0;
  }
};

export const getEquivalentToken = (
  setExchangeRate: any,
  pair: any,
  amount: number,
  token: object,
) => {
  if (!(Object.keys(pair).length === 0) && amount > 0) {
    const {
      reserves0: tokenReserves,
      reserves1: usdcReserves,
      // @ts-ignore
    } = landPair?.info;

    const realReserves = {
      landReserves: tokenReserves / 10 ** 18,
      usdcReserves: usdcReserves / 10 ** 6,
    };

    const total = realReserves.landReserves * realReserves.usdcReserves;

    const newReserves = {
      landReserves: total / (realReserves.usdcReserves + amount),
      usdcReserves: realReserves.usdcReserves + amount,
    };

    const exchangeRate = newReserves.usdcReserves / newReserves.landReserves;
    //   round to 5 decimals
    setExchangeRate(Number(parseFloat(exchangeRate.toString()).toFixed(5)));

    const equivalent =
      realReserves.landReserves -
      newReserves.landReserves -
      0.01 * (realReserves.landReserves - newReserves.landReserves);

    return equivalent;
  } else {
    return 0;
  }
};

type Token = {
  decimals: number;
  label: string;
  price: number;
  url: string;
  value: string;
};

export const convertToUSDC = (pairs: any, amount: number, token: Token) => {
  if (pairs && token) {
    const isWegld = token.value.split('-')[0] === 'WEGLD';
    let isReversed = false;

    // WEGLD - USDC pair details

    const EGLDUSDCPair = pairs.filter((pair: any) => {
      return (
        pair.firstToken.identifier.split('-')[0] === 'WEGLD' &&
        pair.secondToken.identifier.split('-')[0] === 'USDC'
      );
    })[0];

    const EGLDUSDCReserves = EGLDUSDCPair && {
      wegld: EGLDUSDCPair['info']['reserves0'] / 10 ** 18,
      usdc: EGLDUSDCPair['info']['reserves1'] / 10 ** 6,
      total:
        ((EGLDUSDCPair['info']['reserves0'] / 10 ** 18) * EGLDUSDCPair['info']['reserves1']) /
        10 ** 6,
    };

    // WEGLD - TOKEN pair details

    const wegldTokenPair = pairs.filter((pair: any) => {
      const { value } = token;
      const isFirst =
        pair.firstToken.identifier.split('-')[0] === value.split('-')[0] &&
        pair.secondToken.identifier.split('-')[0] === 'WEGLD';
      const isSecond =
        pair.secondToken.identifier.split('-')[0] === value.split('-')[0] &&
        pair.firstToken.identifier.split('-')[0] === 'WEGLD';

      if (isSecond) {
        isReversed = true;
      }

      return isFirst || isSecond;
    })[0];

    // console.log("wegldtokenpair", wegldTokenPair);
    const wegldTokenReserves = wegldTokenPair && {
      wegld: isReversed
        ? wegldTokenPair['info']['reserves0'] / 10 ** token.decimals
        : wegldTokenPair['info']['reserves1'] / 10 ** 18,
      token: isReversed
        ? wegldTokenPair['info']['reserves1'] / 10 ** 18
        : wegldTokenPair['info']['reserves0'] / 10 ** token.decimals,
      total:
        ((wegldTokenPair['info']['reserves1'] / 10 ** 18) * wegldTokenPair['info']['reserves0']) /
        10 ** token.decimals,
    };

    //    new reserves and received tokens amount for WEGLD - TOKEN pair

    const newWegldTokenReserves = wegldTokenReserves && {
      token: wegldTokenReserves['token'] + amount,
      wegld: wegldTokenReserves['total'] / (wegldTokenReserves['token'] + amount),
    };

    const wegldReceived =
      wegldTokenReserves &&
      newWegldTokenReserves &&
      wegldTokenReserves['wegld'] -
        newWegldTokenReserves['wegld'] -
        wegldTokenPair.totalFeePercent *
          (wegldTokenReserves['wegld'] - newWegldTokenReserves['wegld']);

    //   calculate new reserves and received tokens amount for WEGLD - USDC pair

    const newWegldUsdcReserves = EGLDUSDCReserves && {
      wegld: EGLDUSDCReserves['wegld'] + (isWegld ? amount : wegldReceived),
      usdc:
        EGLDUSDCReserves['total'] /
        (EGLDUSDCReserves['wegld'] + (isWegld ? amount : wegldReceived)),
    };

    const usdcReceieved =
      EGLDUSDCReserves &&
      newWegldUsdcReserves &&
      EGLDUSDCReserves['usdc'] -
        newWegldUsdcReserves['usdc'] -
        0.003 * (EGLDUSDCReserves['usdc'] - newWegldUsdcReserves['usdc']);

    return { wegldReceived, usdcReceieved };
  } else {
    return { wegldReceived: 0, usdcReceieved: 0 };
  }
};

export const convertFromUSDC = (pairs: any, amount: number, token: Token) => {
  if (pairs && token) {
    const isWegld = token.value.split('-')[0] === 'WEGLD';
    let isReversed = false;

    // WEGLD - USDC pair details

    const EGLDUSDCPair = pairs.filter((pair: any) => {
      return (
        pair.firstToken.identifier.split('-')[0] === 'WEGLD' &&
        pair.secondToken.identifier.split('-')[0] === 'USDC'
      );
    })[0];

    const EGLDUSDCReserves = EGLDUSDCPair && {
      wegld: EGLDUSDCPair['info']['reserves0'] / 10 ** 18,
      usdc: EGLDUSDCPair['info']['reserves1'] / 10 ** 6,
      total:
        ((EGLDUSDCPair['info']['reserves0'] / 10 ** 18) * EGLDUSDCPair['info']['reserves1']) /
        10 ** 6,
    };

    // WEGLD - TOKEN pair details

    const wegldTokenPair = pairs.filter((pair: any) => {
      const { value } = token;
      const isFirst =
        pair.firstToken.identifier.split('-')[0] === value.split('-')[0] &&
        pair.secondToken.identifier.split('-')[0] === 'WEGLD';
      const isSecond =
        pair.secondToken.identifier.split('-')[0] === value.split('-')[0] &&
        pair.firstToken.identifier.split('-')[0] === 'WEGLD';

      if (isSecond) {
        isReversed = true;
      }

      return isFirst || isSecond;
    })[0];

    // console.log("wegldtokenpair", wegldTokenPair);
    const wegldTokenReserves = wegldTokenPair && {
      wegld: isReversed
        ? wegldTokenPair['info']['reserves0'] / 10 ** token.decimals
        : wegldTokenPair['info']['reserves1'] / 10 ** 18,
      token: isReversed
        ? wegldTokenPair['info']['reserves1'] / 10 ** 18
        : wegldTokenPair['info']['reserves0'] / 10 ** token.decimals,
      total:
        ((wegldTokenPair['info']['reserves1'] / 10 ** 18) * wegldTokenPair['info']['reserves0']) /
        10 ** token.decimals,
    };

    //   calculate new reserves and received tokens amount for WEGLD - USDC pair

    const totalWegldUsdc = EGLDUSDCReserves['usdc'] * EGLDUSDCReserves['wegld'];

    const newWegldUsdcReserves = EGLDUSDCReserves && {
      wegld: totalWegldUsdc / (EGLDUSDCReserves['usdc'] + amount),
      usdc: EGLDUSDCReserves['usdc'] + amount,
    };

    const wegldReceived =
      EGLDUSDCReserves &&
      newWegldUsdcReserves &&
      EGLDUSDCReserves['wegld'] -
        newWegldUsdcReserves['wegld'] -
        0.003 * (EGLDUSDCReserves['wegld'] - newWegldUsdcReserves['wegld']);

    //    new reserves and received tokens amount for WEGLD - TOKEN pair

    const newWegldTokenReserves = wegldTokenReserves && {
      wegld: wegldTokenReserves['wegld'] + (!isWegld ? wegldReceived : amount),
      token:
        wegldTokenReserves['total'] /
        (wegldTokenReserves['wegld'] + (!isWegld ? wegldReceived : amount)),
    };

    // console.log("wegldTokenPair", wegldTokenPair);

    const tokenReceived =
      wegldTokenReserves &&
      newWegldTokenReserves &&
      wegldTokenReserves['token'] -
        newWegldTokenReserves['token'] -
        wegldTokenPair.totalFeePercent *
          (wegldTokenReserves['token'] - newWegldTokenReserves['token']);

    return { wegldReceived, tokenReceived };
  } else {
    return { wegldReceived: 0, usdcReceieved: 0 };
  }
};

export const getExchangeRate = (landPair: any, amount: number) => {
  if (landPair && !(Object.keys(landPair).length === 0)) {
    const {
      reserves0: landReserves,
      reserves1: usdcReserves,
      // @ts-ignore
    } = landPair?.info;

    const realReserves = {
      landReserves: landReserves / 10 ** 18,
      usdcReserves: usdcReserves / 10 ** 6,
    };

    const total = realReserves.landReserves * realReserves.usdcReserves;

    const newReserves = {
      landReserves: landReserves / 10 ** 18 + amount,
      usdcReserves: total / (landReserves / 10 ** 18 + amount),
    };

    const exchangeRate = Number((realReserves.usdcReserves - newReserves.usdcReserves).toFixed(5));

    return exchangeRate;
  } else {
    return 0;
  }
};

export const getLandQuote = () => {
  // getExchangeRate(landPair);
};
