import {
  useGetActiveTransactionsStatus,
  useGetSuccessfulTransactions,
  useGetFailedTransactions,
} from '@multiversx/sdk-dapp/hooks';
import {
  removeAllTransactionsToSign,
  removeAllSignedTransactions,
} from '@multiversx/sdk-dapp/services';
import { useEffect, useState } from 'react';
import { StateType, TransactionType } from 'types/transactions';

const useTransactions = () => {
  const { success, fail, pending } = useGetActiveTransactionsStatus();
  const succesful = useGetSuccessfulTransactions();
  const failed = useGetFailedTransactions();
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [count, setCount] = useState(0);

  const fetchData = () => {
    setCount(count + 1);
    if ((success || fail || !pending) && count > 0) {
      setTransactions([
        ...Object.keys(succesful.successfulTransactions)
          .map(key => succesful.successfulTransactions[key].transactions)
          .flat(),
        ...Object.keys(failed.failedTransactions)
          .map(key => failed.failedTransactions[key].transactions)
          .flat(),
      ]);
      setTimeout(() => {
        removeAllTransactionsToSign();
        removeAllSignedTransactions();
      }, 30000);
    }
  };

  useEffect(fetchData, [pending, fail, success]);

  return { transactions };
};

export default useTransactions;
