import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { fetchTokens } from 'api/transaction';
import Button from 'components/buttons';
// import LabelButton from "components/buttons/LabelButton";
import FarmCard from '../components/cards/FarmCard';
import { Icon } from 'components/icons/Icon';
import Input from 'components/input';
import { farmAddress, proxyNetworkProvider, TOKEN_ID } from 'config';
import { useEffect, useState } from 'react';
import jsonData from '../assets/farming-sc.abi.json';
import {
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address,
  ResultsParser,
  U32Value,
  BigUIntValue,
} from '@elrondnetwork/erdjs/out';
import axios from 'axios';
const Farms = () => {
  const json = JSON.parse(JSON.stringify(jsonData));
  const abiRegistry = AbiRegistry.create(json);
  const abi = new SmartContractAbi(abiRegistry, ['Farming']);
  const contract = new SmartContract({
    address: new Address(farmAddress),
    abi: abi,
  });

  const [items, setItems] = useState([]);
  const [landAmount, setLandAmount] = useState(0);
  const [landBalance, setLandBalance] = useState(0);
  const [rewardApr, setRewardApr] = useState(0);
  const [stakeBalance, setStakeBalance] = useState(0);
  const [stakeReward, setStakeReward] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const { account } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const handleMaxLand = () => {
    setLandAmount(landBalance);
  };

  useEffect(() => {
    axios.get('https://api.elrond.com/tokens/LAND-40f26f').then(res => {
      setTokenPrice(res.data.price);
    });
  }, []);

  useEffect(() => {
    const interaction = contract.methods.getRewardApr([new Address(account.address)]);
    const query = interaction.buildQuery();
    proxyNetworkProvider.queryContract(query).then(res => {
      const endpointDefinition = interaction.getEndpoint();
      const { firstValue, secondValue, returnCode } = new ResultsParser().parseQueryResponse(
        res,
        endpointDefinition,
      );
      if (returnCode.isSuccess() && firstValue) {
        setRewardApr(firstValue.valueOf().toNumber() / Math.pow(10, 18));
      }
    });
  }, [hasPendingTransactions]);

  useEffect(() => {
    const interaction = contract.methods.getBalance([new Address(account.address)]);
    const query = interaction.buildQuery();
    proxyNetworkProvider.queryContract(query).then(res => {
      const endpointDefinition = interaction.getEndpoint();
      const { firstValue, secondValue, returnCode } = new ResultsParser().parseQueryResponse(
        res,
        endpointDefinition,
      );
      if (returnCode.isSuccess() && firstValue) {
        setStakeBalance(firstValue.valueOf().toNumber() / Math.pow(10, 18));
      }
    });
  }, [hasPendingTransactions]);

  useEffect(() => {
    const interaction = contract.methods.getReward([new Address(account.address)]);
    const query = interaction.buildQuery();
    proxyNetworkProvider.queryContract(query).then(res => {
      const endpointDefinition = interaction.getEndpoint();
      const { firstValue, secondValue, returnCode } = new ResultsParser().parseQueryResponse(
        res,
        endpointDefinition,
      );
      if (returnCode.isSuccess() && firstValue) {
        setStakeReward(firstValue.valueOf().toNumber() / Math.pow(10, 18));
      }
    });
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (account.address != '') {
      fetchTokens(network.apiAddress, account.address).then((res: any) => {
        if (res.data?.length > 0) {
          const tokens = res.data.filter(
            (a: any) => a?.identifier === TOKEN_ID || a?.ticker === TOKEN_ID,
          );
          setLandBalance(tokens.length > 0 ? Math.floor(tokens[0]?.balance / 10 ** 18) : 0);
        }
      });
    }
  }, [account]);

  const disabled = landAmount === 0 || !landAmount || !account;

  return (
    <div className="farms-container">
      <div className="farms-container--row">
        <FarmCard
          apr={rewardApr}
          staked={stakeBalance}
          earned={stakeReward}
          subtitle="601,876 locked LAND"
        />
        <div className="farm-card">
          <div className="farm-card--header">
            <h2>STAKE</h2>
          </div>
          <Input
            label="Amount to stake"
            type="number"
            placeholder="Enter stake amount"
            value={landAmount}
            onChange={(e: any) => setLandAmount(e.target.value)}
            // LabelButton={<LabelButton onClick={handleMaxLand} />}
          />
          <Button className="filled" disabled={disabled}>
            <Icon name="flame" color="#fff" /> Stake
          </Button>
        </div>{' '}
      </div>
      <div className="farms-container--row">
        {items.map((item: any, index: number) => (
          <FarmCard
            apr={rewardApr}
            staked={stakeBalance}
            earned={stakeReward}
            subtitle="staked on 03.02.2022"
          />
        ))}
      </div>
    </div>
  );
};

export default Farms;
