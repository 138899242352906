import { motionContainerProps, scaleFadeInVariants } from 'animation/variants';

import { motion } from 'framer-motion';

import { useGetAccountInfo, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';

import { useEffect, useState } from 'react';
import { LKTOKEN_ID1, LKTOKEN_ID2, TOKEN_ID } from 'config';
import axios from 'axios';
import { useAtom } from 'jotai';
import { metaBalanceAtom, phaseAtom, tokensAtom } from 'store/atoms';

import Actions from 'components/unlock/Actions';
import Maintenance from 'components/cards/Maintenance';
import Info from 'components/unlock/Info';

interface UnlockTokensModalProps {
  onClose: () => void;
}
const UnlockTokensModal = ({ onClose }: UnlockTokensModalProps) => {
  const { address, account, ...rest } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const [isInMaintenance, setIsInMaintenance] = useState(false);

  const [tokens, setTokens] = useAtom(tokensAtom);

  useEffect(() => {
    if (account.address != '') {
      axios
        .get(`${network.apiAddress}/accounts/${account.address}/tokens?size=100`)
        .then((res: any) => {
          if (res.data?.length > 0) {
            const tokensArray = res.data.filter(
              (a: any) =>
                a.identifier === TOKEN_ID ||
                a.identifier === LKTOKEN_ID1 ||
                a.identifier === LKTOKEN_ID2,
            );
            const tokensDict = tokensArray.reduce((acc: any, curr: any) => {
              acc[curr.identifier] = curr;
              return acc;
            }, {});
            setTokens({ ...tokens, ...tokensDict });
          }
        });
    }
  }, [account]);

  return (
    <div className="lboard-modal unlock-tokens-modal">
      <motion.div
        className="lboard-modal__content"
        {...motionContainerProps}
        variants={scaleFadeInVariants}
      >
        {isInMaintenance ? (
          <Maintenance onClose={onClose} />
        ) : (
          <>
            <h1>Claim locked LAND</h1>
            <div className="lboard-modal__body">
              <Info />
              <Actions onClose={onClose} tokens={tokens} />
              {/* <div>
            <p className="text-xs">
              In order to unlock, you first need to migrate the tokens, then to claim the available
              amount.
            </p>
          </div> */}
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default UnlockTokensModal;
