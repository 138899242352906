import {
  ApiNetworkProvider,
  ProxyNetworkProvider,
} from '@elrondnetwork/erdjs-network-providers/out';

export const dAppName = 'LandBoard Staking';

const convertToHex = (str: string) => {
  var hex, i;

  var result = '';
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }

  return result;
};
export const chainID = process.env.NODE_ENV === 'production' ? '1' : 'D';

export const TOKEN_ID = process.env.NODE_ENV === 'production' ? 'LAND-40f26f' : 'SVEN-0deee5';
export const LKTOKEN_ID1 = 'LKLAND-6cf78e';
export const LKTOKEN_ID2 = 'LKLAND-c617f7';

export const networkProvider = new ApiNetworkProvider(
  `https://${process.env.NODE_ENV === 'production' ? '' : 'devnet-'}api.elrond.com`,
);

export const proxyNetworkProvider = new ProxyNetworkProvider(
  `https://${process.env.NODE_ENV === 'production' ? '' : 'devnet-'}gateway.elrond.com`,
);

export const SHARD_0_WRAP = 'erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy';
export const SHARD_1_WRAP = 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3';
export const SHARD_2_WRAP = 'erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln';

export const TOKEN_ID_ONLY_HEX = convertToHex(TOKEN_ID);
export const TOKEN_ID_HEX = '0x' + TOKEN_ID_ONLY_HEX;
export const REFERRRAL_ACTIVATION_AMOUNT = 100000000000000000000;
export const APY_INCREASE_PER_REFERRAL = 50;
export const MAX_APY_INCREASE_BY_REFERRAL = 1000;
export const LKTOKEN_META_ID =
  process.env.NODE_ENV === 'production' ? 'LKLAND-516da7' : 'LKTEST-304da7';
export const LKTOKEN_SECOND_META_ID =
  process.env.NODE_ENV === 'production' ? 'LKLAND-27462c' : 'LKTEST-304da7';

// contract functions

export const STAKE = 'stake';
export const STAKE_ONLY_HEX = convertToHex(STAKE);
export const SWITCH_TOKENS = 'switchTokens';
export const UNLOCK_ASSETS = 'unlockAssets';

export const CONTRACT_ADDRESS = {
  [TOKEN_ID]:
    process.env.NODE_ENV === 'production'
      ? 'erd1qqqqqqqqqqqqqpgqggd9ep2kvwkmtl6u4ysz62wv0kt0z6kjjpmqtv8a9t'
      : 'erd1qqqqqqqqqqqqqpgqusgl5w8834pm64a4x93xk2wjcdr8thdyjpmq5epwkh',
  [LKTOKEN_ID1]:
    process.env.NODE_ENV === 'production'
      ? 'erd1qqqqqqqqqqqqqpgqg2d0lwguqx9cmkyyvfspwd9lyhqfcxxyjpmqhhule6'
      : 'erd1qqqqqqqqqqqqqpgqusgl5w8834pm64a4x93xk2wjcdr8thdyjpmq5epwkh',
  [LKTOKEN_ID2]:
    process.env.NODE_ENV === 'production'
      ? 'erd1qqqqqqqqqqqqqpgqgyc05rxl8jq89dwumxk5cuyvrcdtujn7jpmqlrddnk'
      : 'erd1qqqqqqqqqqqqqpgqusgl5w8834pm64a4x93xk2wjcdr8thdyjpmq5epwkh',
  [LKTOKEN_META_ID]:
    process.env.NODE_ENV === 'production'
      ? 'erd1qqqqqqqqqqqqqpgqeg3vkch67smdjl7fexk7wz7lacuff9upjpmqn6t3ky'
      : 'erd1qqqqqqqqqqqqqpgqvkfy2lvgs9p4m3gmw2y0f9fh3rcyt5sa23qs6u8eyd',
  [LKTOKEN_SECOND_META_ID]:
    process.env.NODE_ENV === 'production'
      ? 'erd1qqqqqqqqqqqqqpgqh4nrqg57lmw92gqpp54gjm336q4rul96jpmqmp9yxg'
      : 'erd1qqqqqqqqqqqqqpgqvkfy2lvgs9p4m3gmw2y0f9fh3rcyt5sa23qs6u8eyd',
};

// TODO: change this to the correct address
export const LOCK_CONTRACT =
  process.env.NODE_ENV === 'production'
    ? 'erd1qqqqqqqqqqqqqpgqxdw9frftz64lhsynfaflr4razc9n3rxkjpmqpv48kr'
    : 'erd1qqqqqqqqqqqqqpgqxdw9frftz64lhsynfaflr4razc9n3rxkjpmqpv48kr';

export const UNLOCK_CONTRACT =
  process.env.NODE_ENV === 'production'
    ? 'erd1qqqqqqqqqqqqqpgq5qq7jgvujcdr6sg4kf0ws6wnckswvqyvjpmqx6w98r'
    : 'erd1qqqqqqqqqqqqqpgq96pde9v9huqg7sp3tmh6jzfk54r7eldv23qsdmxgrm';

export const SECOND_UNLOCK_CONTRACT =
  process.env.NODE_ENV === 'production'
    ? 'erd1qqqqqqqqqqqqqpgqfytd3qvg7wf9ugkqzkpr4e3xeusutz9djpmq2kg9tw'
    : 'erd1qqqqqqqqqqqqqpgq96pde9v9huqg7sp3tmh6jzfk54r7eldv23qsdmxgrm';

export const CONTRACT_ABI_URL = '/assets/abi/landboard-node-stake.abi.json';
export const META_CONTRACT_ABI_URL = '/assets/abi/meta-staking.abi.json';
export const CONTRACT_NAME = 'LandboardStaking';
export const TIMEOUT = 6000;
export const LAUNCH_TIME = 1648278000000;
export const ENVIRONMENT = process.env.NODE_ENV === 'production' ? 'mainnet' : 'devnet';

export const ABI_URL = 'https://api.npoint.io/e1a9bf49a1338fe558c3';

export const walletConnectV2ProjectId = '04152d1c0f4571d292452022cffd28c2';

export const MAIAR_ADDRESS =
  process.env.NODE_ENV === 'production'
    ? 'erd1qqqqqqqqqqqqqpgqq66xk9gfr4esuhem3jru86wg5hvp33a62jps2fy57p'
    : 'erd1qqqqqqqqqqqqqpgqnz9ctnn9776xa7ua6u6s0tfz2zvn39ehjpmqr0kp9h';

export const liquidityAddress =
  process.env.NODE_ENV === 'production'
    ? 'erd1qqqqqqqqqqqqqpgqn530ruk9qkffaucewc3rnf2damhptz2r2jpszcf84t'
    : 'erd1qqqqqqqqqqqqqpgqn530ruk9qkffaucewc3rnf2damhptz2r2jpszcf84t';

export const farmAddress =
  process.env.NODE_ENV === 'production'
    ? ''
    : 'erd1qqqqqqqqqqqqqpgqp6fzdpfgqx2heqyzxkvuthlygpmy0xqa23qsfc3am8';

export const multiPairSwap = 'multiPairSwap';
export const swapTokensFixedInput = 'swapTokensFixedInput';
export const USDC_ID = 'USDC-c76f1f';
export const WEGLD_ID = 'WEGLD-bd4d79';

export const BLAST_API = 'https://elrond-api.blastapi.io/c08cc31b-1e76-4fdd-8d0e-82d47b260406';

export const landPlans = [
  {
    id: 1,
    title: 'Lander',
    days: 15,
    apr: 39.57,
  },
  {
    id: 2,
    title: 'Fan',
    days: 30,
    apr: 41.15,
  },
  {
    id: 3,
    title: 'LandLord',
    days: 60,
    apr: 43.52,
  },
  {
    id: 4,
    title: 'Wizard',
    days: 120,
    apr: 45.9,
  },
  {
    id: 5,
    title: 'Cyborg',
    days: 240,
    apr: 50.65,
  },
];

export const lockedLandPlans = [
  {
    id: 2,
    title: 'Fan',
    days: 30,
    apr: 52 * 0.25,
  },
  {
    id: 3,
    title: 'LandLord',
    days: 60,
    apr: 55 * 0.25,
  },
  {
    id: 4,
    title: 'Wizard',
    days: 120,
    apr: 58 * 0.25,
  },
  {
    id: 5,
    title: 'Cyborg',
    days: 240,
    apr: 64 * 0.25,
  },
];

// export const PRESALE_1_UNLOCK_START = 1761095800000;
export const PRESALE_1_UNLOCK_START = 1661002400000;
export const PRESALE_2_UNLOCK_START = 1663342200000;
