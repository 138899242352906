import {
  useGetAccountInfo,
  useGetAccountProvider,
  useGetLoginInfo,
  useGetNetworkConfig,
  useSignTransactions,
} from '@multiversx/sdk-dapp/hooks';

import { sendTransactionSmart, sendTransactionUSDC } from 'api/maiar';
import { getBalance, notifyTransaction } from 'api/transaction';
import axios from 'axios';
import Button from 'components/buttons';
import { LabelButton } from 'components/cards/BuyTokenCard';
import LboardSelect from 'components/dropdown';
import Dracula from 'components/icons/Dracula';
import Input from 'components/input';
import SignTransactionsModal from 'components/modals/SignTransactionsModal';
import { networkProvider, TOKEN_ID, USDC_ID, WEGLD_ID } from 'config';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { convertFromUSDC, convertToUSDC, getEquivalent, getExchangeRate } from 'utils/swap';
import useTimeUntil from 'utils/useTimeUntil';
import { AnimatePresence, motion } from 'framer-motion';

interface FormValues {
  swapToId: string;
  swapToAmount: string;
  swapFromId: string;
  swapFromAmount: string;
  slippage: number;
}

const swapBonusEnds = 1657897200000;

const Swap = () => {
  const { handleSubmit, register, formState, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      swapToId: TOKEN_ID,
      swapToAmount: '0',
      swapFromId: 'USDC',
      swapFromAmount: '0',
      slippage: 1,
    },
  });

  const [allTokens, setAllTokens] = useState<any[]>([{ value: 'None', label: 'Select' }]);
  const [pairs, setPairs] = useState([]);
  const [landPair, setLandPair] = useState({});
  const [usdcExchangeRate, setUsdcExchangeRate] = useState(0);
  const [realExchangeRate, setRealExchangeRate] = useState(0);
  const [balance, setBalance] = useState(0);
  const [wegldAmount, setWegldAmount] = useState(0);
  const [usdcAmount, setUsdcAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState({ token: 0, land: 0 });
  const [landBalance, setLandBalance] = useState(0);
  const { transactions } = useSignTransactions();
  const { providerType, provider } = useGetAccountProvider();
  const [providerInstance, setProviderInstance] = useState({});
  const [userShard, setUserShard] = useState(0);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [txHash, setTxHash] = useState([]);
  const [isFirstTxConfirmed, setIsFirstTxConfirmed] = useState(false);

  const swapToId = watch('swapToId');
  const swapFromId = watch('swapFromId');
  const swapFromAmount = watch('swapFromAmount');
  const swapToAmount = watch('swapToAmount');
  const slippage = watch('slippage');

  const [selectedOption, setSelectedOption] = useState<any>('EGLD');

  const { isLoggedIn } = useGetLoginInfo();
  const { timeLeft, days, hours, minutes, seconds } = useTimeUntil(swapBonusEnds);

  const { account } = useGetAccountInfo();
  const info = useGetAccountProvider();
  const { network } = useGetNetworkConfig();

  useEffect(() => {
    if (transaction.length > 0 && !isConfirmed) {
      setShowModal(false);
      const interval = setInterval(() => {
        axios
          .get(`${networkProvider['url']}/transactions/${txHash.length == 2 ? txHash[1] : txHash}`)
          .then(res => {
            if (res.data.status == 'success') {
              setIsConfirmed(true);
              txHash.length !== 2
                ? notifyTransaction({
                    // @ts-ignore
                    ...transaction,
                    status: 'success',
                    hash: txHash[0],
                  })
                : notifyTransaction({
                    // @ts-ignore
                    ...transaction[1],
                    status: 'success',
                    hash: txHash[1],
                  });
              clearInterval(interval);
            } else {
              txHash.length !== 2
                ? notifyTransaction({
                    ...transaction,
                    status: 'pending',
                    hash: txHash,
                  })
                : notifyTransaction({
                    // @ts-ignore
                    ...transaction[1],
                    status: 'pending',
                    hash: txHash[1],
                  });
            }
          });
      }, 5000);
    }
  }, [transaction]);

  //   useEffect(() => {
  //     const watcher = new TransactionWatcher(networkProvider);

  //       console.log("transactionOnNetwork", transactionOnNetwork);
  //     };

  //     transaction.length > 0 && getInfo();
  //     console.log("trnsaction", transaction);
  //   }, [networkProvider, transaction]);

  useEffect(() => {
    setProviderInstance(provider);
  }, [providerType, provider]);

  useEffect(() => {
    axios
      .get(`${networkProvider['url']}/accounts/${account.address}`)
      .then(res => setUserShard(res.data.shard));
  }, [account]);

  const onSubmit = async (ev: any) => {
    ev.preventDefault();
    const selectedToken = allTokens.filter(token => token.value === selectedOption)[0];

    const selectedPair = pairs.filter(pair =>
      selectedToken.value !== 'EGLD'
        ? pair['firstToken']['identifier'] === selectedToken.value ||
          pair['secondToken']['identifier'] === selectedToken.value
        : pair['firstToken']['identifier'] === WEGLD_ID ||
          pair['secondToken']['identifier'] === USDC_ID,
    )[0];

    selectedOption && selectedOption !== USDC_ID
      ? await sendTransactionSmart(
          account,
          parseFloat((Number(swapFromAmount) - Number(0.00000001)).toFixed(6).toString()),
          parseFloat(swapToAmount),
          selectedOption,
          selectedToken.decimals,
          Number(usdcAmount.toFixed(5)),
          selectedPair['address'],
          wegldAmount,
          Number(slippage),
          selectedOption == 'EGLD' ? true : false,
          selectedOption.split('-')[0] == 'WEGLD' ? true : false,
          providerInstance,
          userShard,
          setTransaction,
          setTxHash,
          setShowModal,
        )
      : await sendTransactionUSDC(
          account,
          parseFloat(swapFromAmount),
          parseFloat(swapToAmount),
          selectedOption,
          selectedToken.decimals,
          providerInstance,
        );
  };

  useEffect(() => {
    register('swapFromId', {
      required: true,
    });
    register('swapToId', {
      required: true,
    });
  }, []);

  useEffect(() => {
    axios.get(`https://api.elrond.com/mex/tokens`).then(async (res: any) => {
      const tokens = res.data.map((token: any) => {
        return { value: token.id, label: token.name };
      });

      const { data: tokenInfos } = await axios.get(
        `https://api.elrond.com/tokens?identifiers=${tokens
          .map((token: any) => token.value)
          .join(',')}`,
      );

      setAllTokens(
        tokens.map((token: any) => {
          const wantedToken = tokenInfos.find(
            (tokenInfo: any) => tokenInfo.identifier === token.value,
          );

          return {
            ...token,
            label: token.value.split('-')[0],
            price: wantedToken?.price,
            decimals: wantedToken?.decimals,
            url: wantedToken?.assets.pngUrl,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    getPairs().then(res => {
      setPairs(res.data.data.pairs);
      setLandPair(
        res.data.data.pairs.filter(
          (pair: any) => pair.firstToken.identifier === TOKEN_ID && pair.state === 'Active',
        )[0],
      );
    });
  }, [swapFromId, swapToId]);

  useEffect(() => {
    const egldPair =
      allTokens &&
      allTokens.filter((pair: any) => {
        return pair['value'].split('-')[0] === 'WEGLD';
      })[0];

    allTokens.unshift({
      ...egldPair,
      value: 'EGLD',
      label: 'EGLD',
      url: 'https://arda.run/tokens/EGLD.png',
    });
  }, [pairs, allTokens]);

  useEffect(() => {
    setUsdcExchangeRate(getExchangeRate(landPair, 1));
  }, [landPair]);

  const getPairs = async () => {
    const res = await axios.post('https://graph.maiar.exchange/graphql', {
      query:
        '\n      {\n        pairs(offset: 0, limit: 500) {\n          address\n          state\n          totalFeePercent\n          info {\n            reserves0\n            reserves1\n            totalSupply\n          }\n          firstToken {\n            identifier\n          }\n          secondToken {\n            identifier\n          }\n          liquidityPoolToken {\n            identifier\n            decimals\n          }\n        }\n      }\n    ',
    });

    return res;
  };

  const handleUpdateInputs = (value: number, isMax: boolean) => {
    const wantedToken = allTokens.filter(token => token.value === selectedOption)[0];
    const { usdcReceieved, wegldReceived } = convertToUSDC(
      pairs,
      value,
      !(selectedOption == 'EGLD')
        ? wantedToken
        : allTokens.filter(token => token.value === 'WEGLD-bd4d79')[0],
    );

    setWegldAmount(wegldReceived);
    setUsdcAmount(usdcReceieved);

    let equivalent = {};

    if (selectedOption == 'EGLD') {
      equivalent = getEquivalent(setUsdcExchangeRate, landPair, usdcReceieved, false);
    } else {
      equivalent =
        selectedOption?.split('-')[0] == 'USDC'
          ? getEquivalent(setUsdcExchangeRate, landPair, value, false)
          : getEquivalent(setUsdcExchangeRate, landPair, usdcReceieved, false);
    }

    isMax &&
      setValue(
        'swapFromAmount',
        selectedOption !== 'EGLD' ? value.toString() : (value - 0.0004).toString(),
      );

    equivalent >= 0 && setValue('swapToAmount', parseFloat(equivalent?.toString()).toFixed(5));
  };

  const handleUpdateInputsReverse = (value: number, isMax: boolean) => {
    const wantedToken = allTokens.filter(token => token.value === selectedOption)[0];

    let equivalent = {};

    if (selectedOption == 'EGLD') {
      equivalent = getEquivalent(setUsdcExchangeRate, landPair, value, true);
    } else {
      equivalent =
        selectedOption?.split('-')[0] == 'USDC'
          ? getEquivalent(setUsdcExchangeRate, landPair, value, true)
          : getEquivalent(setUsdcExchangeRate, landPair, value, true);
    }

    const { wegldReceived, tokenReceived } = convertFromUSDC(
      pairs,
      Number(equivalent),
      //   100,
      !(selectedOption == 'EGLD')
        ? wantedToken
        : allTokens.filter(token => token.value === 'WEGLD-bd4d79')[0],
    );

    setWegldAmount(wegldReceived);
    setUsdcAmount(wegldReceived);

    // getEquivalentToken();
    equivalent >= 0 &&
      setValue(
        'swapFromAmount',
        parseFloat(
          selectedOption !== 'EGLD'
            ? selectedOption?.split('-')[0] !== 'USDC'
              ? tokenReceived?.toString()
              : equivalent.toString()
            : wegldReceived?.toString(),
        ).toFixed(5),
      );
  };

  useEffect(() => {
    const wantedToken = allTokens.filter(token => token.value === selectedOption)[0];

    const exchangeRate = getExchangeRate(landPair, 1);

    setTokenPrice({
      token: wantedToken?.price * Number(swapFromAmount),
      land: exchangeRate * Number(swapToAmount),
    });
  }, [allTokens, selectedOption, swapFromAmount]);

  useEffect(() => {
    setBalance(0);
    setValue('swapFromAmount', '0');
    setValue('swapToAmount', '0');
    getBalance(account.address).then(res => {
      const wantedToken = res?.balance?.filter((a: any) => a['identifier'] == selectedOption)[0];
      const landToken = res?.balance?.filter((a: any) => a['identifier'] == TOKEN_ID)[0];

      setLandBalance(Number((landToken?.balance / 10 ** 18).toFixed(5)));

      setBalance(
        wantedToken
          ? Number((wantedToken.balance / 10 ** wantedToken.decimals).toFixed(5))
          : selectedOption == 'EGLD'
          ? Number((Number(account.balance) / 10 ** 18).toFixed(5))
          : 0,
      );
    });
  }, [selectedOption, landPair]);

  useEffect(() => {
    const { usdcReceieved, wegldReceived } = convertToUSDC(
      pairs,
      Number(Number(swapFromAmount) > 0 ? swapFromAmount : 1),
      !(selectedOption == 'EGLD')
        ? allTokens.filter(token => token.value === selectedOption)[0]
        : allTokens.filter(token => token.value === 'WEGLD-bd4d79')[0],
    );

    setRealExchangeRate(
      Number(
        getEquivalent(
          setUsdcExchangeRate,
          landPair,
          Number(swapFromAmount) > 0 ? usdcReceieved / Number(swapFromAmount) : usdcReceieved,
          false,
        ).toFixed(5),
      ),
    );
  }, [swapFromAmount, selectedOption]);

  const disabled = !formState.isValid || !formState.isDirty || !isLoggedIn;
  const progressWidth =
    (Number(swapToAmount) >= 400 ? 100 : (Number(swapToAmount) / 400) * 100) + '%';

  return (
    <>
      {isUnderMaintenance ? (
        <div className="maintenance-wrapper">
          <Dracula /> <p>Swaps are currently under scheduled maintenance. </p>
          <p>
            Coming live soon.
            <a href="https://t.me/landboardio"> Keep an eye on updates! </a>
          </p>
        </div>
      ) : (
        <div className="swap-container">
          <div className="header">
            <h1>Swap</h1>
            <p>Directly swap between any token listed on Maiar Exchange and LAND.</p>
          </div>
          <div className="swap-card">
            <form onSubmit={onSubmit}>
              <div className="amount-wrapper">
                <div className="input-with-select">
                  <Input
                    {...register('swapFromAmount', {
                      valueAsNumber: true,
                    })}
                    containerClassName="offsetted-container"
                    onChange={e => {
                      if (e.target.value == '') {
                        handleUpdateInputs(0, false);
                        setValue('swapFromAmount', '');
                      } else {
                        handleUpdateInputs(parseFloat(e.target.value), false);
                        setValue('swapFromAmount', e.target.value);
                      }
                    }}
                    label="Swap from:"
                    type="number"
                  />
                  <LboardSelect
                    containerClassname="absolute-select"
                    options={allTokens}
                    defaultValue={allTokens.length > 0 && allTokens[0]}
                    onChange={(e: any) => {
                      setSelectedOption(e.value);
                    }}
                  />
                </div>
                {tokenPrice && <p className="token-price">≈ ${tokenPrice['token'].toFixed(7)}</p>}
              </div>

              <div className="balance-wrapper">
                <p className="small-text">
                  Balance: <span>{balance}</span>
                </p>
                <LabelButton onClick={() => handleUpdateInputs(balance, true)} />
              </div>

              <div className="amount-wrapper">
                <div className="input-with-select">
                  <Input
                    {...register('swapToAmount', {
                      pattern: {
                        value: /^[0-9]*$/,
                        message: 'Please enter a valid amount',
                      },
                    })}
                    containerClassName="offsetted-container"
                    onChange={e => {
                      handleUpdateInputsReverse(parseFloat(e.target.value), false);
                      setValue('swapToAmount', e.target.value);
                    }}
                    label="Swap to:"
                    type="number"
                  />
                  <LboardSelect
                    containerClassname="absolute-select"
                    options={[
                      {
                        value: 'LAND-40f26f',
                        label: <div>LAND</div>,
                        url:
                          'https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/LAND-40f26f/logo.svg',
                      },
                    ]}
                  />
                </div>
                {tokenPrice && <p className="token-price">≈ ${tokenPrice['land'].toFixed(7)}</p>}
              </div>

              <p className="small-text" id="balance-land">
                Balance: <span> {landBalance}</span>
              </p>
              <div className="swap-card-actions">
                <Input
                  {...register('slippage', {
                    valueAsNumber: true,
                  })}
                  onChange={e => {
                    setValue('slippage', Number(e.target.value));
                  }}
                  label="Slippage"
                  type="number"
                />
                <Button
                  type="submit"
                  className={timeLeft > 0 ? 'outline relative' : 'filled'}
                  onClick={onSubmit}
                  hideComingSoon
                >
                  {timeLeft > 0 && <div className="progress" style={{ width: progressWidth }} />}{' '}
                  Swap
                </Button>
              </div>

              {timeLeft > 0 &&
                (progressWidth == '100%' ? (
                  <p className="small-text">Congrats! You will be airdropped an NFT Badge! </p>
                ) : (
                  <div className="event-wrapper">
                    <p className="small-text">
                      Event: Swap at least 400 LAND before the timer runs out and gain a special
                      "LAND Trader" NFT Badge!{' '}
                    </p>
                    <span className="text-xl text-purple">
                      {days}D {hours}H {minutes}M {seconds}S
                    </span>
                  </div>
                ))}
            </form>
            <h2 className="my-5">Details</h2>
            <ul>
              <li>
                Exchange rate: 1 {selectedOption.split('-')[0]} = {realExchangeRate}{' '}
                {swapToId.split('-')[0]}
              </li>
              <li>
                Min Received:{' '}
                {(Number(swapToAmount) - Number((slippage / 100) * Number(swapToAmount))).toFixed(
                  5,
                )}{' '}
                LAND ({slippage}% Slippage)
              </li>
            </ul>
          </div>
          <AnimatePresence>
            {showModal && (
              <SignTransactionsModal
                onClose={() => {
                  setShowModal(false);
                }}
              />
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default Swap;
