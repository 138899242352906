import Button from '../buttons';
import { Icon } from '../icons/Icon';

const FarmIcon = () => (
  <svg width="71" height="72" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="48" height="42.7538" rx="21.3769" fill="url(#paint0_linear_1453_4521)" />
    <path
      d="M30.4769 31.6509V30.7169H26.423H26.3911V32.5929H35.5682C36.0869 32.5929 36.5178 32.1733 36.5178 31.6509C36.5178 31.1364 36.0948 30.709 35.5682 30.709H31.5861V31.6509H30.4769V31.6509Z"
      fill="#1C1819"
    />
    <path
      d="M18.5228 30.7168V31.6587H17.4136V30.7168H13.4316C12.9129 30.7168 12.4819 31.1363 12.4819 31.6587C12.4819 32.1732 12.9049 32.6007 13.4316 32.6007H22.6086V30.7168H18.5228Z"
      fill="#1C1819"
    />
    <path d="M18.5228 30.7168H17.4136V31.6587H18.5228V30.7168Z" fill="#1C1819" />
    <path d="M31.5863 30.7168H30.4771V31.6587H31.5863V30.7168Z" fill="#1C1819" />
    <path
      d="M22.6089 33.7723C22.6089 34.3105 23.0478 34.7538 23.5984 34.7538H25.4099C25.9525 34.7538 26.3994 34.3184 26.3994 33.7723V32.585H22.6169V33.7723H22.6089Z"
      fill="#1C1819"
    />
    <path d="M26.3914 30.7168H22.6089V32.5928H26.3914V30.7168Z" fill="#1C1819" />
    <path
      d="M33.3899 9.87598H31.1794L20.7974 26.8625H23.0078L27.2133 19.984L38.0023 25.8334C38.3854 25.3664 38.6088 24.7728 38.6088 24.1237V24.0287L28.1949 18.385L33.3899 9.87598Z"
      fill="#1C1819"
    />
    <path d="M13.9744 26.8625H15.9135L19.6322 9.87598H17.701L13.9744 26.8625Z" fill="#1C1819" />
    <path
      d="M35.8476 8H13.1524C10.5828 8 8.5 10.0738 8.5 12.6147V24.1158C8.5 26.6645 10.5908 28.7305 13.1524 28.7305H17.4137V27.7885H18.5229V28.7384H22.6087V27.7964H26.3913V28.7384H26.4232H30.477V27.7964H31.5863V28.7384H35.8476C38.4172 28.7384 40.5 26.6645 40.5 24.1237V12.6147C40.508 10.0738 38.4172 8 35.8476 8ZM38.4092 26.0471L38.0102 25.8334C37.4995 26.4587 36.7254 26.8624 35.8556 26.8624H23.0077L21.9384 28.6197L20.3184 27.6461L20.7972 26.8624H15.9135L15.8416 27.2028H13.9025L13.9743 26.8624H13.1444C11.6202 26.8624 10.3833 25.6355 10.3833 24.1237V12.6147C10.3833 11.1028 11.6202 9.87595 13.1444 9.87595H17.693L17.9404 8.73613L19.7918 9.1319L19.6322 9.86804H31.1793L31.8097 8.83112L33.4297 9.80471L33.3898 9.86804H35.8476C37.3718 9.86804 38.6087 11.0949 38.6087 12.6068V24.0129L39.3109 24.3928L38.4092 26.0471Z"
      fill="#1C1819"
    />
    <path d="M18.5228 27.7959H17.4136V28.7378H18.5228V27.7959Z" fill="#1C1819" />
    <path d="M31.5863 27.7959H30.4771V28.7378H31.5863V27.7959Z" fill="#1C1819" />
    <path
      d="M33.4296 9.81246L31.8096 8.83887L31.1792 9.87579H33.3897L33.4296 9.81246Z"
      fill="#1C1819"
    />
    <path
      d="M20.3184 27.6459L21.9383 28.6195L23.0076 26.8623H20.7972L20.3184 27.6459Z"
      fill="#1C1819"
    />
    <path
      d="M38.6167 24.1244C38.6167 24.7734 38.3853 25.3671 38.0103 25.8341L38.4093 26.0478L39.319 24.4014L38.6167 24.0215V24.1244Z"
      fill="#1C1819"
    />
    <path d="M26.3914 27.7959H22.6089V28.7378H26.3914V27.7959Z" fill="#1C1819" />
    <path
      d="M19.7917 9.14001L17.9483 8.73633L17.7009 9.87615H19.6321L19.7917 9.14001Z"
      fill="#1C1819"
    />
    <path d="M13.9023 27.2027H15.8415L15.9133 26.8623H13.9742L13.9023 27.2027Z" fill="#1C1819" />
    <path d="M26.4231 28.7383H26.3912V30.7171H26.4231V28.7383Z" fill="#1C1819" />
    <path d="M26.3913 28.7383H22.6088V30.7171H26.3913V28.7383Z" fill="#1C1819" />
    <path d="M26.3913 28.7383H22.6088V30.7171H26.3913V28.7383Z" fill="#1C1819" />
    <path d="M31.5863 28.7383H30.4771V30.7171H31.5863V28.7383Z" fill="#1C1819" />
    <path d="M31.5863 28.7383H30.4771V30.7171H31.5863V28.7383Z" fill="#1C1819" />
    <path d="M18.5229 28.7383H17.4137V30.7171H18.5229V28.7383Z" fill="#1C1819" />
    <path d="M18.5229 28.7383H17.4137V30.7171H18.5229V28.7383Z" fill="#1C1819" />
    <rect
      x="26.5"
      y="28"
      width="44"
      height="43.7199"
      rx="21.86"
      fill="url(#paint1_linear_1453_4521)"
    />
    <path
      d="M56.7879 41.4393C56.6759 41.3165 56.6199 41.1938 56.6199 41.0404C56.6199 40.887 56.6759 40.7643 56.7879 40.6415C57.0119 40.4268 57.3479 40.4268 57.5719 40.6415C57.6839 40.7643 57.7399 40.887 57.7399 41.0404C57.7399 41.1938 57.6839 41.3165 57.5719 41.4393C57.4599 41.5313 57.3199 41.5927 57.1799 41.5927C57.0399 41.6233 56.8999 41.562 56.7879 41.4393"
      fill="#1C1819"
    />
    <path
      d="M55.3758 42.8454C55.2824 42.7218 55.2202 42.5982 55.2202 42.4437C55.2202 42.2893 55.2824 42.1657 55.3758 42.0421C55.5935 41.8258 55.9669 41.8258 56.1847 42.0421C56.278 42.1657 56.3402 42.2893 56.3402 42.4437C56.3402 42.5982 56.278 42.7218 56.1847 42.8454C56.0602 42.9381 55.9358 42.9999 55.7802 42.9999C55.6247 42.9999 55.5002 42.9381 55.3758 42.8454"
      fill="#1C1819"
    />
    <path
      d="M53.9756 44.5251C53.8823 44.4324 53.8201 44.2779 53.8201 44.1234C53.8201 43.9689 53.8823 43.8454 53.9756 43.7218C54.1934 43.5055 54.5667 43.5055 54.7845 43.7218C54.8778 43.8145 54.9401 43.9689 54.9401 44.1234C54.9401 44.2779 54.8778 44.4015 54.7845 44.5251C54.6601 44.6487 54.5356 44.6796 54.3801 44.6796C54.2245 44.6796 54.069 44.6178 53.9757 44.5251"
      fill="#1C1819"
    />
    <path
      d="M52.5879 45.9255C52.4759 45.8328 52.4199 45.6783 52.4199 45.5238C52.4199 45.3693 52.4759 45.2458 52.5879 45.1222C52.8119 44.9059 53.1479 44.9059 53.3719 45.1222C53.4839 45.2149 53.5399 45.3693 53.5399 45.5238C53.5399 45.6783 53.4839 45.8328 53.3719 45.9255C53.2599 46.0491 53.1199 46.08 52.9799 46.08C52.8399 46.08 52.6999 46.0491 52.5879 45.9255"
      fill="#1C1819"
    />
    <path
      d="M51.1756 47.3182C51.0511 47.2261 51.02 47.0727 51.02 46.9193C51.02 46.7659 51.0822 46.6432 51.1756 46.5204C51.3934 46.3057 51.7667 46.3057 51.9845 46.5204C52.1089 46.6125 52.14 46.7659 52.14 46.9193C52.14 47.0727 52.0778 47.2261 51.9845 47.3182C51.8911 47.4409 51.7356 47.4716 51.58 47.4716C51.4245 47.5022 51.2689 47.4409 51.1756 47.3182"
      fill="#1C1819"
    />
    <path
      d="M49.3398 48.3246C49.3398 48.1701 49.4021 48.0465 49.4954 47.9229C49.7132 47.7067 50.0865 47.7067 50.3043 47.9229C50.4287 48.0465 50.4598 48.1701 50.4598 48.3246C50.4598 48.4791 50.3976 48.6027 50.3043 48.7263C50.211 48.819 50.0554 48.8807 49.8999 48.8807C49.7443 48.8807 49.6199 48.819 49.4954 48.7263C49.4021 48.6336 49.3399 48.4791 49.3399 48.3246H49.3398Z"
      fill="#1C1819"
    />
    <path
      d="M46.6956 51.7986C46.6023 51.7066 46.54 51.5532 46.54 51.3998C46.54 51.2464 46.6023 51.1236 46.6956 51.0009C46.9134 50.7862 47.2867 50.7862 47.5045 51.0009C47.6289 51.1236 47.66 51.2464 47.66 51.3998C47.66 51.5532 47.5978 51.6759 47.5045 51.7986C47.4112 51.9214 47.2556 51.952 47.1001 51.952C46.9445 51.9827 46.8201 51.9214 46.6956 51.7986"
      fill="#1C1819"
    />
    <path
      d="M45.3079 53.198C45.1959 53.106 45.1399 52.9526 45.1399 52.7992C45.1399 52.6458 45.1959 52.5231 45.3079 52.4003C45.5319 52.1856 45.8679 52.1856 46.0919 52.4003C46.2039 52.5231 46.2599 52.6458 46.2599 52.7992C46.2599 52.9526 46.2039 53.0753 46.0919 53.198C45.9799 53.3208 45.8399 53.3515 45.6999 53.3515C45.5599 53.3821 45.4199 53.3208 45.3079 53.198Z"
      fill="#1C1819"
    />
    <path
      d="M43.8955 54.6052C43.8022 54.5125 43.74 54.358 43.74 54.2035C43.74 54.049 43.8022 53.8946 43.8955 53.8019C44.1133 53.5856 44.4867 53.5856 44.7044 53.8019C44.7978 53.8945 44.86 54.049 44.86 54.2035C44.86 54.358 44.7978 54.4816 44.7044 54.6052C44.58 54.7288 44.4555 54.7596 44.3 54.7596C44.1445 54.7596 44.02 54.6979 43.8956 54.6052"
      fill="#1C1819"
    />
    <path
      d="M42.2156 56.0056C42.0912 55.9129 42.0601 55.7584 42.0601 55.6039C42.0601 55.4494 42.1223 55.3258 42.2156 55.2022C42.4334 54.986 42.8067 54.986 43.0245 55.2022C43.1178 55.3258 43.1801 55.4494 43.1801 55.6039C43.1801 55.7584 43.1178 55.9128 43.0245 56.0056C42.9001 56.1291 42.7756 56.16 42.6201 56.16C42.4645 56.16 42.309 56.1291 42.2156 56.0056"
      fill="#1C1819"
    />
    <path
      d="M40.832 57.406C40.7171 57.3133 40.6597 57.1588 40.6597 57.0043C40.6597 56.8498 40.7171 56.7262 40.832 56.6026C41.0617 56.3864 41.4063 56.3864 41.6073 56.6026C41.7222 56.7262 41.7797 56.8498 41.7797 57.0043C41.7797 57.1588 41.7222 57.3132 41.6073 57.406C41.4925 57.4986 41.3489 57.5604 41.2053 57.5604C41.0617 57.5604 40.9181 57.5295 40.832 57.406Z"
      fill="#1C1819"
    />
    <path
      d="M39.4158 59.0789C39.2914 58.9562 39.2603 58.8335 39.2603 58.68C39.2603 58.5266 39.3225 58.4039 39.4158 58.2812C39.6336 58.0664 40.0069 58.0664 40.2247 58.2812C40.3491 58.4039 40.3803 58.5266 40.3803 58.68C40.3803 58.8335 40.318 58.9562 40.2247 59.0789C40.1314 59.1709 39.9758 59.2323 39.8203 59.2323C39.6958 59.263 39.5403 59.2016 39.4158 59.0789"
      fill="#1C1819"
    />
    <path
      d="M40.2242 41.4393C40.3175 41.3165 40.3798 41.1938 40.3798 41.0404C40.3798 40.887 40.3175 40.7643 40.2242 40.6415C40.0064 40.4268 39.6331 40.4268 39.4153 40.6415C39.2909 40.7643 39.2598 40.887 39.2598 41.0404C39.2598 41.1938 39.322 41.3165 39.4153 41.4393C39.5087 41.5313 39.6642 41.5927 39.8198 41.5927C39.9753 41.6233 40.1309 41.562 40.2242 41.4393"
      fill="#1C1819"
    />
    <path
      d="M41.6122 42.8454C41.7242 42.7218 41.7802 42.5982 41.7802 42.4437C41.7802 42.2893 41.7242 42.1657 41.6122 42.0421C41.3882 41.8258 41.0522 41.8258 40.8282 42.0421C40.7162 42.1657 40.6602 42.2893 40.6602 42.4437C40.6602 42.5982 40.7162 42.7218 40.8282 42.8454C40.9402 42.9381 41.0802 42.9999 41.2202 42.9999C41.3602 42.9999 41.5002 42.9381 41.6122 42.8454"
      fill="#1C1819"
    />
    <path
      d="M43.0245 44.5251C43.1178 44.4324 43.1801 44.2779 43.1801 44.1234C43.1801 43.9689 43.1178 43.8454 43.0245 43.7218C42.8067 43.5055 42.4334 43.5055 42.2156 43.7218C42.1223 43.8145 42.0601 43.9689 42.0601 44.1234C42.0601 44.2779 42.1223 44.4015 42.2156 44.5251C42.3401 44.6487 42.4645 44.6796 42.62 44.6796C42.7445 44.6796 42.9 44.6178 43.0245 44.5251"
      fill="#1C1819"
    />
    <path
      d="M44.7042 45.9255C44.7975 45.8328 44.8597 45.6783 44.8597 45.5238C44.8597 45.3693 44.7975 45.2458 44.7042 45.1222C44.4864 44.9059 44.1131 44.9059 43.8953 45.1222C43.802 45.2149 43.7397 45.3693 43.7397 45.5238C43.7397 45.6783 43.802 45.8328 43.8953 45.9255C44.0197 46.0491 44.1442 46.08 44.2997 46.08C44.4242 46.08 44.5797 46.0491 44.7042 45.9255"
      fill="#1C1819"
    />
    <path
      d="M46.0878 47.3182C46.2027 47.2261 46.2601 47.0727 46.2601 46.9193C46.2601 46.7659 46.2027 46.6432 46.0878 46.5204C45.8868 46.3057 45.5135 46.3057 45.3124 46.5204C45.1976 46.6125 45.1401 46.7659 45.1401 46.9193C45.1401 47.0727 45.1976 47.2261 45.3124 47.3182C45.4273 47.4409 45.5709 47.4716 45.7145 47.4716C45.8581 47.5022 46.0017 47.4409 46.0878 47.3182Z"
      fill="#1C1819"
    />
    <path
      d="M47.66 48.3246C47.66 48.1701 47.5978 48.0465 47.5045 47.9229C47.2867 47.7067 46.9134 47.7067 46.6956 47.9229C46.5712 48.0465 46.54 48.1701 46.54 48.3246C46.54 48.4791 46.6023 48.6027 46.6956 48.7263C46.7889 48.819 46.9445 48.8807 47.1 48.8807C47.2556 48.8807 47.38 48.819 47.5045 48.7263C47.6289 48.6336 47.66 48.4791 47.66 48.3246H47.66Z"
      fill="#1C1819"
    />
    <path
      d="M48.9044 50.406C49.0288 50.2824 49.0599 50.1588 49.0599 50.0043C49.0599 49.8498 48.9977 49.7262 48.9044 49.6026C48.6866 49.3864 48.3133 49.3864 48.0955 49.6026C47.9711 49.7262 47.9399 49.8498 47.9399 50.0043C47.9399 50.1588 48.0022 50.2824 48.0955 50.406C48.1888 50.4986 48.3444 50.5604 48.4999 50.5604C48.6555 50.5604 48.811 50.4986 48.9044 50.406"
      fill="#1C1819"
    />
    <path
      d="M50.2878 51.7986C50.4026 51.7066 50.4601 51.5532 50.4601 51.3998C50.4601 51.2464 50.4026 51.1236 50.2878 51.0009C50.058 50.7862 49.7134 50.7862 49.5124 51.0009C49.3975 51.1236 49.3401 51.2464 49.3401 51.3998C49.3401 51.5532 49.3975 51.6759 49.5124 51.7986C49.6273 51.9214 49.7709 51.952 49.9144 51.952C50.0293 51.9827 50.1729 51.9214 50.2878 51.7986Z"
      fill="#1C1819"
    />
    <path
      d="M51.9842 53.198C52.0776 53.106 52.1398 52.9526 52.1398 52.7992C52.1398 52.6458 52.0776 52.5231 51.9842 52.4003C51.7664 52.1856 51.3931 52.1856 51.1753 52.4003C51.082 52.5231 51.0198 52.6458 51.0198 52.7992C51.0198 52.9526 51.082 53.0753 51.1753 53.198C51.2998 53.3208 51.4242 53.3515 51.5798 53.3515C51.7042 53.3821 51.8598 53.3208 51.9842 53.198"
      fill="#1C1819"
    />
    <path
      d="M53.3844 54.6052C53.4777 54.5125 53.5399 54.358 53.5399 54.2035C53.5399 54.049 53.4777 53.8946 53.3844 53.8019C53.1666 53.5856 52.7933 53.5856 52.5755 53.8019C52.4821 53.8945 52.4199 54.049 52.4199 54.2035C52.4199 54.358 52.4821 54.4816 52.5755 54.6052C52.6999 54.7288 52.8244 54.7596 52.9799 54.7596C53.1354 54.7596 53.2599 54.6979 53.3843 54.6052"
      fill="#1C1819"
    />
    <path
      d="M54.7843 56.0056C54.9087 55.9129 54.9398 55.7584 54.9398 55.6039C54.9398 55.4494 54.8776 55.3258 54.7843 55.2022C54.5665 54.986 54.1932 54.986 53.9754 55.2022C53.882 55.3258 53.8198 55.4494 53.8198 55.6039C53.8198 55.7584 53.882 55.9128 53.9754 56.0056C54.0998 56.1291 54.2243 56.16 54.3798 56.16C54.5353 56.16 54.6909 56.1291 54.7842 56.0056"
      fill="#1C1819"
    />
    <path
      d="M56.1842 57.406C56.3086 57.3133 56.3397 57.1588 56.3397 57.0043C56.3397 56.8498 56.2775 56.7262 56.1842 56.6026C55.9664 56.3864 55.5931 56.3864 55.3753 56.6026C55.2508 56.7262 55.2197 56.8498 55.2197 57.0043C55.2197 57.1588 55.2819 57.3132 55.3753 57.406C55.4686 57.4986 55.6242 57.5604 55.7797 57.5604C55.9353 57.5604 56.0908 57.5295 56.1841 57.406"
      fill="#1C1819"
    />
    <path
      d="M57.5678 59.0789C57.6827 58.9562 57.7401 58.8335 57.7401 58.68C57.7401 58.5266 57.6827 58.4039 57.5678 58.2812C57.3381 58.0664 56.9934 58.0664 56.7924 58.2812C56.6776 58.4039 56.6201 58.5266 56.6201 58.68C56.6201 58.8335 56.6776 58.9562 56.7924 59.0789C56.9073 59.1709 57.0509 59.2323 57.1945 59.2323C57.3093 59.263 57.4529 59.2016 57.5678 59.0789Z"
      fill="#1C1819"
    />
    <path
      d="M43.75 41.88C45.2241 40.9189 46.8401 40.4666 48.6259 40.4383C50.3835 40.4383 51.9993 40.9189 53.5018 41.88L56.6202 38.7704C54.1822 36.9329 51.5457 36 48.6259 36C45.7061 36 43.0414 36.9329 40.6602 38.7704L43.7503 41.88H43.75Z"
      fill="#1C1819"
    />
    <path
      d="M40.38 54.6883C39.3761 53.1832 38.8885 51.5643 38.8885 49.8323C38.8885 48.0715 39.3761 46.453 40.38 44.9762L37.2535 41.8809C35.4178 44.2663 34.5 46.9074 34.5 49.8608C34.5 52.7574 35.4178 55.4269 37.2822 57.8408L40.3799 54.6885L40.38 54.6883Z"
      fill="#1C1819"
    />
    <path
      d="M53.5298 57.8399C52.0557 58.8292 50.4397 59.338 48.6822 59.3662C46.8963 59.3662 45.2522 58.8857 43.778 57.8681L40.6597 60.9492C43.0693 62.8148 45.7341 63.7476 48.6822 63.7193C51.602 63.691 54.2385 62.7583 56.6196 60.9492L53.5295 57.8398L53.5298 57.8399Z"
      fill="#1C1819"
    />
    <path
      d="M56.6199 45.0321C57.6092 46.5372 58.118 48.1843 58.0897 49.9735C58.0614 51.7058 57.5809 53.2963 56.6199 54.7444L59.7293 57.8398C61.5666 55.4543 62.4993 52.8133 62.4993 49.9167C62.5276 46.9633 61.5948 44.2937 59.7293 41.8799L56.6199 45.0321V45.0321Z"
      fill="#1C1819"
    />
    <path
      d="M59.6999 58.6797C60.9217 58.6797 61.9399 59.6688 61.9399 60.9197C61.9399 62.1415 60.9508 63.1597 59.6999 63.1597C58.4781 63.1597 57.46 62.1706 57.46 60.9197C57.46 59.6688 58.4491 58.6797 59.6999 58.6797Z"
      fill="#1C1819"
    />
    <path
      d="M37.3 58.6797C38.5379 58.6797 39.54 59.6688 39.54 60.9197C39.54 62.1415 38.5379 63.1597 37.3 63.1597C36.0621 63.1597 35.0601 62.1706 35.0601 60.9197C35.0601 59.6688 36.0621 58.6797 37.3 58.6797Z"
      fill="#1C1819"
    />
    <path
      d="M59.7 36.2803C60.9379 36.2803 61.94 37.2694 61.94 38.5203C61.94 39.7712 60.9379 40.7602 59.7 40.7602C58.4621 40.7602 57.46 39.7712 57.46 38.5203C57.46 37.2694 58.4621 36.2803 59.7 36.2803Z"
      fill="#1C1819"
    />
    <path
      d="M37.3 36.2803C38.5218 36.2803 39.54 37.2824 39.54 38.5203C39.54 39.7582 38.5509 40.7602 37.3 40.7602C36.0782 40.7602 35.06 39.7582 35.06 38.5203C35.06 37.2824 36.0491 36.2803 37.3 36.2803Z"
      fill="#1C1819"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1453_4521"
        x1="1.18571"
        y1="2.22676"
        x2="22.3595"
        y2="52.0758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8E2DE2" />
        <stop offset="1" stop-color="#4A00E0" />
        <stop offset="1" stop-color="#4A00E0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1453_4521"
        x1="27.1286"
        y1="30.2771"
        x2="50.413"
        y2="79.4164"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8E2DE2" />
        <stop offset="1" stop-color="#4A00E0" />
        <stop offset="1" stop-color="#4A00E0" />
      </linearGradient>
    </defs>
  </svg>
);

interface FarmCardProps {
  id?: number;
  apr: number;
  staked: number;
  earned: number;
  subtitle?: string;
  onClick?: () => void;
}

const FarmCard = ({ apr, staked, earned, id, subtitle, onClick }: FarmCardProps) => {
  return (
    <div className="farm-card">
      <div className="farm-card--header">
        <FarmIcon />
        <div>
          <h2>LAND-EGLD</h2>
          <span>{subtitle}</span>
        </div>
      </div>
      <ul>
        <li>
          <span>
            <Icon name="chart" width={24} primary /> APR
          </span>
          <span>{apr}%</span>
        </li>
        <li>
          <span>
            <Icon name="coins" width={24} primary /> Staked LAND
          </span>
          <span>{staked}</span>
        </li>
        <li>
          <span>
            <Icon name="gift" width={24} primary /> Earned LAND
          </span>
          <span>{earned}</span>
        </li>
      </ul>
      {id && (
        <Button className="mt-2" onClick={onClick}>
          Unstake
        </Button>
      )}
    </div>
  );
};

export default FarmCard;
