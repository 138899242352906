const Logo = () => {
  return (
    <img
      src="/assets/images/logo.png"
      alt="Logo"
      className="md:w-[13.75rem] md:h-[2.875rem] h-[2.25rem] w-[10.75rem]"
    />
  );
};

export default Logo;
