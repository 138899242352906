import { motionContainerProps, scaleFadeInVariants } from 'animation/variants';
import Button from 'components/buttons';
import { motion } from 'framer-motion';
import loaderJSON from 'assets/lottie/loader.json';
import Lottie from 'react-lottie-player';

interface SignTransactionsProps {
  onClose: () => void;
}
const SignTransactionsModal = ({ onClose }: SignTransactionsProps) => {
  return (
    <div className="lboard-modal sign-transactions-modal">
      <motion.div
        className="lboard-modal__content"
        {...motionContainerProps}
        variants={scaleFadeInVariants}
      >
        <Lottie
          animationData={loaderJSON}
          style={{ width: 100, height: 100, margin: '0 auto' }}
          loop
          play
        />
        <h3>Sign transactions</h3>
        <p>Please check your Elrond wallet of choice to sign the transaction.</p>
        <Button className="text" onClick={onClose}>
          Cancel
        </Button>
      </motion.div>
    </div>
  );
};

export default SignTransactionsModal;
