import React from 'react';
import { Icon as LBIcon } from 'components/icons/Icon';
import {
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  PRESALE_1_UNLOCK_START,
  PRESALE_2_UNLOCK_START,
  TOKEN_ID,
} from 'config';
import useGetBalance from '../../hooks/useGetBalance';
import Dates from './Dates';
import { useAtom } from 'jotai';
import { tokensAtom } from 'store/atoms';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const Info = () => {
  const { address, account, ...rest } = useGetAccountInfo();

  const { metaBalance, totalBalance } = useGetBalance(1);
  const { metaBalance: metaBalanceSecond, totalBalance: metaBalanceSecondTotal } = useGetBalance(2);

  const [tokens, setTokens] = useAtom(tokensAtom);

  return (
    <ul>
      <li>
        <span className="lboard-modal__label">
          <LBIcon name="land" primary />
          LAND
        </span>
        <div className="flex flex-col gap-3 text-sm">
          <span className="text-end">{(tokens[TOKEN_ID].balance / 10 ** 18).toFixed(4)} LAND</span>
        </div>
      </li>
      <li>
        <span className="lboard-modal__label">
          <LBIcon name="land" primary />
          LKLAND (Presale 1)
        </span>
        <div className="flex flex-col gap-3 text-sm">
          <span> {(tokens[LKTOKEN_ID2].balance / 10 ** 18 + totalBalance).toFixed(4)} LKLAND </span>
          {/* <Button className="btn-primary small" onClick={() => handleLock('X')}>
      {step[LKTOKEN_ID1] == 'migrate' ? 'Migrate' : 'Unlock'}
    </Button> */}
        </div>
        <Dates startingDate={PRESALE_1_UNLOCK_START} />
      </li>
      <li>
        <span className="lboard-modal__label">
          <LBIcon name="land" primary />
          LKLAND (Presale 2)
        </span>
        <div className="flex flex-col gap-3 text-sm">
          {/* <span> {(tokens[LKTOKEN_ID1].balance / 10 ** 18).toFixed(4)} LKLAND </span> */}
          <span>
            {' '}
            {Number(
              Number((tokens[LKTOKEN_ID1].balance / 10 ** 18).toFixed(4)) + metaBalanceSecondTotal,
            ).toFixed(4)}{' '}
            LKLAND{' '}
          </span>
        </div>
        <Dates startingDate={PRESALE_2_UNLOCK_START} />
      </li>

      <li>
        <span className="lboard-modal__label">
          <LBIcon name="egld" primary />
          EGLD
        </span>
        <span className="text-sm"> {(Number(account.balance) / 10 ** 18).toFixed(4)} EGLD</span>
      </li>
      {/* 
      <li>
        <span className="lboard-modal__label">
          <LBIcon name="calendar_star" primary />
          Next claim Time
        </span>
        <span>{dayjs.unix(unlockDate).format('MMM DD, YYYY hh:mm A')}</span>
      </li> */}
    </ul>
  );
};

export default Info;
