import { TOKEN_ID, LKTOKEN_ID1, LKTOKEN_ID2 } from 'config';
import { atom } from 'jotai';

export const showModalAtom = atom(false);
export const isOpenAtom = atom(false);

export const phaseAtom = atom(0);

export const metaBalanceAtom = atom([]);

export const presaleSelectedAtom = atom(0);
export const sessionIdAtom = atom<string | null>(null);
export const transactionStatusAtom = atom<any>(null);

export const tokensAtom = atom<Record<string, any>>({
  [TOKEN_ID]: { balance: 0 },
  [LKTOKEN_ID1]: { balance: 0 },
  [LKTOKEN_ID2]: { balance: 0 },
});
