import { ReactComponent as BxLineChart } from './bx-line-chart.svg';
import { ReactComponent as BxsZap } from './bxs-zap.svg';
import { ReactComponent as CalendarCheck } from './calendar_check.svg';
import { ReactComponent as CalendarStar } from './calendar_star.svg';
import { ReactComponent as Chart } from './chart.svg';
import { ReactComponent as Chrome } from './chrome.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Coins } from './coins.svg';
import { ReactComponent as Cyborg } from './cyborg.svg';
import { ReactComponent as Discord } from './discord.svg';
import { ReactComponent as Egld } from './egld.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as Fan } from './fan.svg';
import { ReactComponent as Flame } from './flame.svg';
import { ReactComponent as GiftCopy } from './gift copy.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as Hamburger } from './hamburger.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Info2 } from './info2.svg';
import { ReactComponent as Land } from './land.svg';
import { ReactComponent as Lander } from './lander.svg';
import { ReactComponent as Landlord } from './landlord.svg';
import { ReactComponent as Leaderboard } from './leaderboard.svg';
import { ReactComponent as MapPin } from './map-pin.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as Marketplace } from './marketplace.svg';
import { ReactComponent as Money } from './money.svg';
import { ReactComponent as Palette } from './palette.svg';
import { ReactComponent as Percentage } from './percentage.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Tag1 } from './tag1.svg';
import { ReactComponent as Tag2 } from './tag2.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as Tile } from './tile.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Unlock } from './unlock.svg';
import { ReactComponent as UserLocation } from './user-location.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Wizard } from './wizard.svg';

export default {
  'bx-line-chart': BxLineChart,
  'bxs-zap': BxsZap,
  calendar_check: CalendarCheck,
  calendar_star: CalendarStar,
  chart: Chart,
  chrome: Chrome,
  close: Close,
  coins: Coins,
  cyborg: Cyborg,
  discord: Discord,
  egld: Egld,
  expand: Expand,
  fan: Fan,
  flame: Flame,
  'gift copy': GiftCopy,
  gift: Gift,
  hamburger: Hamburger,
  history: History,
  info: Info,
  info2: Info2,
  land: Land,
  lander: Lander,
  landlord: Landlord,
  leaderboard: Leaderboard,
  'map-pin': MapPin,
  map: Map,
  marketplace: Marketplace,
  money: Money,
  palette: Palette,
  percentage: Percentage,
  share: Share,
  tag1: Tag1,
  tag2: Tag2,
  telegram: Telegram,
  tile: Tile,
  twitter: Twitter,
  unlock: Unlock,
  'user-location': UserLocation,
  user: User,
  wizard: Wizard,
};
