import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { BLAST_API, LKTOKEN_ID1, LKTOKEN_ID2 } from 'config';
import { useAtom } from 'jotai';
import React from 'react';
import { presaleSelectedAtom, sessionIdAtom, tokensAtom, transactionStatusAtom } from 'store/atoms';
import useUnlockContract from 'utils/useUnlockContract';
import Button from '../';
import BigNumber from 'bignumber.js';
import axios from 'axios';

const MigrateButton = ({ presale, transactionStatus }: any) => {
  const { unlockContract } = useUnlockContract('a');
  const { address, account, ...rest } = useGetAccountInfo();

  const [tokens, setTokens] = useAtom(tokensAtom);
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);
  const [presaleSelected, setPresaleSelected] = useAtom(presaleSelectedAtom);

  const wantedToken = presale == 1 ? LKTOKEN_ID2 : LKTOKEN_ID1;
  // const wantedToken = 'BSK-baa025';
  const isFirst = presale === 1;

  // temporary fix
  // TODO: Fix this

  const getBalance = async () => {
    try {
      const res = await axios.get(
        `${BLAST_API}/accounts/${address}/tokens?identifier=${isFirst ? LKTOKEN_ID2 : LKTOKEN_ID1}`,
      );

      return res.data[0].balance;
    } catch (error) {
      return 0;
    }
  };

  const handleMigration = async () => {
    const amountToSend = await getBalance();
    // const amountToSend = 1;

    setPresaleSelected(isFirst ? 1 : 2);

    const amountString = amountToSend.toString();

    let sessionId =
      unlockContract &&
      (await unlockContract.createLockTransaction(
        account,
        isFirst ? LKTOKEN_ID2 : LKTOKEN_ID1,
        amountToSend,
        isFirst,
      ));

    setSessionId(sessionId);
  };

  return (
    <Button
      className="filled"
      hideAnimate
      hideComingSoon
      disabled={tokens[wantedToken]?.balance == 0}
      // disabled={true}
      onClick={() => handleMigration()}
    >
      {tokens[wantedToken]?.balance == 0 ? (
        <span>Nothing to unlock</span>
      ) : transactionStatus.isPending && presaleSelected == presale ? (
        <span>Migrating...</span>
      ) : (
        <span>Migrate Presale {presale}</span>
      )}
    </Button>
  );
};

export default MigrateButton;
