import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import {
  Account,
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  I8Value,
  Interaction,
  OptionValue,
  ResultsParser,
  SmartContract,
  TokenTransfer,
  Transaction,
  TransactionPayload,
  TypedValue,
  U32Value,
  U8Value,
} from '@multiversx/sdk-core/out';
import {
  chainID,
  CONTRACT_ADDRESS,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  STAKE,
  TOKEN_ID,
} from 'config';
import { notifyTransaction } from './transaction';

export default class StakeContract {
  contract: SmartContract;
  stakerAddress: Address;
  stakerAccount: Account;
  provider: ApiNetworkProvider;

  constructor(account: any, contract: SmartContract, provider: ApiNetworkProvider) {
    this.stakerAddress = account.address;
    this.stakerAccount = account;
    this.contract = contract;
    this.provider = provider;
  }

  createStakeTransaction = async (
    tokenId: string,
    stakeTypeId: number = 1,
    landAmount: number = 100,
    isMeta: boolean,
    nonce: number,
    address: string,
    referralAddress?: string,
  ) => {
    const args: TypedValue[] = !isMeta
      ? [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(TokenTransfer.egldFromAmount(landAmount).valueOf()),
          BytesValue.fromUTF8(STAKE),
          new U32Value(stakeTypeId),
        ]
      : [
          BytesValue.fromUTF8(tokenId),
          new BigUIntValue(nonce),
          new BigUIntValue(TokenTransfer.egldFromAmount(landAmount).valueOf()),
          new AddressValue(
            new Address(
              tokenId == LKTOKEN_META_ID
                ? CONTRACT_ADDRESS[LKTOKEN_META_ID]
                : CONTRACT_ADDRESS[LKTOKEN_SECOND_META_ID],
            ),
          ),
          BytesValue.fromUTF8(STAKE),
          new U32Value(stakeTypeId),
        ];

    if (referralAddress) args.push(new AddressValue(new Address(referralAddress)));

    let { argumentsString } = new ArgSerializer().valuesToString(args);
    argumentsString = argumentsString.toUpperCase();
    const data = !isMeta
      ? new TransactionPayload(`ESDTTransfer@${argumentsString}`)
      : new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

    let tx = new Transaction({
      receiver: !isMeta ? new Address(CONTRACT_ADDRESS[tokenId]) : new Address(address),
      gasLimit: 10000000,
      sender: new Address(this.stakerAddress),
      data: data,
      chainID: chainID,
    });
    tx.setNonce(this.stakerAccount.nonce.valueOf());
    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
  };

  createUnstakeTransaction = async (nodeId: number) => {
    let tx = this.contract.call({
      func: new ContractFunction('unstake'),
      gasLimit: 6000000,
      caller: new Address(this.stakerAddress),
      args: [new I8Value(nodeId)],
      chainID: chainID,
    });

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
    // notifyTransaction({ ...tx, status: "pending" });
  };

  createClaimTransaction = async (nodeId: number) => {
    let tx = this.contract.call({
      func: new ContractFunction('claim'),
      caller: new Address(this.stakerAddress),
      gasLimit: 50000000,
      args: [new U32Value(nodeId)],
      chainID: chainID,
    });
    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: tx,
    });

    return sessionId;
    // notifyTransaction({ ...tx, status: "pending" });
  };

  getStakeTypes = async () => {
    const interaction: Interaction = this.contract.methodsExplicit.getStakeTypes();
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );

    return firstValue;
  };

  getStakerAddresses = async () => {
    const interaction: Interaction = this.contract.methodsExplicit.getStakerAddresses();
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );

    return firstValue;
  };

  getNodesPerStaker = async () => {
    const args = [new AddressValue(new Address(this.stakerAddress))];
    const interaction: Interaction = this.contract.methodsExplicit.getNodesPerStaker(args);
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );

    return firstValue;
  };

  getReferredCount = async () => {
    const args = [new AddressValue(new Address(this.stakerAddress))];
    const interaction: Interaction = this.contract.methodsExplicit.getReferredCount(args);
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );

    return firstValue;
  };

  getApyOfStaker = async () => {
    const args = [new AddressValue(new Address(this.stakerAddress))];
    const interaction: Interaction = this.contract.methodsExplicit.getApyOfStaker(args);
    let query = interaction.check().buildQuery();
    let parser = new ResultsParser();

    let queryResponse = await this.provider.queryContract(query);
    let endpointDefinition = interaction.getEndpoint();
    let { firstValue, secondValue, returnCode } = parser.parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );

    return firstValue;
  };
}
