import React from 'react';
import { useAtom } from 'jotai';
import { presaleSelectedAtom, sessionIdAtom, tokensAtom, transactionStatusAtom } from 'store/atoms';

import Button from '..';
import { LKTOKEN_META_ID, LKTOKEN_SECOND_META_ID } from 'config';
import useUnlockContract from 'utils/useUnlockContract';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import dayjs from 'dayjs';
import useTimeUntil from 'utils/useTimeUntil';

const ClaimButton = ({
  presale,
  balance,
  phase,
  transactionStatus,
  nonce,
}: {
  presale: number;
  balance: number;
  phase: number;
  transactionStatus: any;
  nonce: number;
}) => {
  const { unlockContract } = useUnlockContract('a');
  const { address, account, ...rest } = useGetAccountInfo();

  const [tokens, setTokens] = useAtom(tokensAtom);
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);
  const [presaleSelected, setPresaleSelected] = useAtom(presaleSelectedAtom);

  const isFirst = presale === 1;

  const unlockDate = dayjs()
    .add(0, 'day')
    .unix();
  const { timeLeft, days: d, hours, minutes, seconds } = useTimeUntil(unlockDate * 1000);

  const handleUnlock = async () => {
    const amountToSend = balance * 10 ** 18;
    // const amountToSend = isFirst ? 0.8 * 10 ** 18 : 5 * 10 ** 18;
    const amountString = amountToSend.toString();

    setPresaleSelected(isFirst ? 1 : 2);

    let sessionId =
      unlockContract &&
      (await unlockContract.createUnlockTransaction(
        account,
        isFirst ? LKTOKEN_META_ID : LKTOKEN_SECOND_META_ID,
        nonce,
        amountString.slice(-10) !== '0000000000'
          ? amountToSend / 10 ** 18 - 0.0001
          : amountToSend / 10 ** 18,
        isFirst,
      ));

    setSessionId(sessionId);
  };

  const handleSwitch = async () => {
    const amountToSend = balance;
    // const amountToSend = 0.72;
    const amountString = amountToSend.toString();

    let sessionId =
      unlockContract &&
      (await unlockContract.createSwitchMetaTransaction(
        account,
        LKTOKEN_META_ID,
        nonce,
        amountToSend,
      ));

    setSessionId(sessionId);
  };

  return (
    <Button
      className="filled"
      hideAnimate
      hideComingSoon
      onClick={() => {
        (nonce == 1 || nonce == 2) && presale == 1 ? handleSwitch() : handleUnlock();
      }}
    >
      {timeLeft > 0 ? (
        <span>
          Claim in {d}D : {hours}H : {minutes}M : {seconds}S
        </span>
      ) : transactionStatus.isPending && isFirst ? (
        `Claiming ~  ${
          nonce == 1 || nonce == 3
            ? Number(balance / 5).toFixed(5)
            : nonce == 4
            ? Number((balance * 10) / 9 / 10).toFixed(5)
            : Number(balance / 8).toFixed(5)
        } LAND...`
      ) : (
        `Claim ~ ${
          nonce == 1 || nonce == 3
            ? Number(balance / 5).toFixed(5)
            : nonce == 4
            ? Number(balance / 9).toFixed(5)
            : Number(balance / 8).toFixed(5)
        } LAND (Presale ${presale})`
      )}
    </Button>
  );
};

export default ClaimButton;
