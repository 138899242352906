import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig,
} from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';

import { scaleInVariants } from 'animation/variants';
import axios from 'axios';
import { Icon } from 'components/icons/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isOpenAtom, showModalAtom } from 'store/atoms';

import Button from '.';

const variants = {
  hidden: {
    x: '100%',
  },
  visible: {
    x: '0%',
  },
};

const AddressButton = ({ onClick, isMobile }: any) => {
  const { isLoggedIn } = useGetLoginInfo();
  const { address, account } = useGetAccountInfo();
  const [totalLandBalance, setTotalLandBalance] = useState(0);
  const navigate = useNavigate();
  const isInUnlock = window.location.pathname.includes('unlock');
  const { network } = useGetNetworkConfig();
  const [showModal, setShowModal] = useAtom(showModalAtom);
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);

  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const handleUnlock = () => {
    if (isInUnlock) {
      navigate('/');
    } else {
      navigate('/unlock');
    }
    onClick();
  };

  useEffect(() => {
    if (account.address != '') {
      axios
        .get(`${network.apiAddress}/accounts/${account.address}/tokens?size=100`)
        .then((res: any) => {
          if (res.data?.length > 0)
            setTotalLandBalance(
              res.data.filter(
                (a: any) =>
                  a.identifier === 'LAND-40f26f' ||
                  a.ticker === 'SVEN-4b35b0' ||
                  a.ticker === 'SVEN-0deee5',
              )[0]?.balance /
                10 ** 18,
            );
        });
    }
  }, [account]);

  return (
    <div className="address-buttons">
      {!isLoggedIn && (
        <Button className="z-10 filled min-w-[14rem]" onClick={handleUnlock} animate>
          <Icon name="unlock" />
          {isInUnlock ? 'Back' : 'Connect'}
        </Button>
      )}
      <AnimatePresence>
        {isLoggedIn && (
          <div className="flex flex-col ">
            <motion.div className="flex mb-5" variants={scaleInVariants}>
              <div className="account-button__address">
                <span className="font-bold text-purple">
                  {address.slice(0, 6)}...{address.slice(-4)}
                </span>
                <div className="account-button__tag-container">
                  <span className="account-button__tag">{totalLandBalance.toFixed(2)} LAND</span>
                </div>
              </div>
              <Button className="filled w-[8.75rem]" onClick={handleLogout}>
                <Icon name="unlock" />
                Disconnect
              </Button>
            </motion.div>
            <div className="flex items-center justify-center w-full p-2 bg-purple-darkest">
              <Button
                onClick={() => {
                  isMobile && setIsOpen(prevState => !prevState);
                  setShowModal(prevState => !prevState);
                }}
              >
                Claim LKLAND
              </Button>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AddressButton;
