import withPageTitle from 'components/seo/PageTitle';
import BuyTokens from 'pages/BuyTokens';
import Farms from 'pages/Farms';
import Home from 'pages/Home';
import Referrals from 'pages/Referrals';
import Staked from 'pages/Staked';
import Swap from 'pages/Swap';

export const routeNames = {
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  referrals: '/referrals',
  staking: '/?',
  staked: '/staked',
  farm: '/farm',
  swap: '/swap',
  buy: '/buy',
};

const routes: Array<any> = [
  {
    path: routeNames.staked,
    component: Staked,
    exact: true,
    authenticatedRoute: true,
  },
  {
    path: routeNames.referrals,
    component: Referrals,
    exact: true,
    authenticatedRoute: true,
  },
  {
    path: routeNames.staking,
    component: Home,
    exact: true,
  },
  {
    path: routeNames.farm,
    component: Farms,
    exact: true,
  },
  {
    path: routeNames.swap,
    component: Swap,
    exact: true,
  },
  {
    path: routeNames.buy,
    component: BuyTokens,
    exact: true,
  },
];

const mappedRoutes = routes.map(route => {
  const title = route.title ? `${route.title} • Landboard` : 'Landboard';

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
