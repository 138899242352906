import React, { useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import { Icon as LBIcon } from 'components/icons/Icon';
import dayjs from 'dayjs';

const Dates = ({ startingDate }: any) => {
  let dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      {' '}
      <Tooltip
        placement="top"
        trigger={['hover']}
        overlay={
          <div className="flex flex-col">
            <span className="tooltip-title">Unlock periods</span>
            <ul>
              {dates.map((item, index) => {
                return (
                  <li className="tooltip-item" key={item}>
                    10% -{' '}
                    {dayjs(startingDate)
                      .add(30 * index, 'day')
                      .format('DD/MM')}
                  </li>
                );
              })}
            </ul>
          </div>
        }
      >
        <LBIcon name="info" primary />
      </Tooltip>
    </div>
  );
};

export default Dates;
