import { addressIsValid } from '@multiversx/sdk-dapp/utils';
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useTrackTransactionStatus,
} from '@multiversx/sdk-dapp/hooks';
import { fadeInVariants, motionContainerProps } from 'animation/variants';
import { getBalance } from 'api/transaction';
import axios from 'axios';
import Button from 'components/buttons';
import TokenPicker from 'components/buttons/TokenPicker';
import PlanCard from 'components/cards';
import CheckboxGroup from 'components/checkbox/CheckboxGroup';
import { Icon } from 'components/icons/Icon';
import Input from 'components/input';
import UnlockTokensModal from 'components/modals/UnlockTokensModal';
import {
  landPlans,
  LAUNCH_TIME,
  LKTOKEN_ID1,
  LKTOKEN_ID2,
  LKTOKEN_META_ID,
  LKTOKEN_SECOND_META_ID,
  lockedLandPlans,
  TOKEN_ID,
} from 'config';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { metaBalanceAtom, sessionIdAtom, showModalAtom } from 'store/atoms';
import useStakeContract from 'utils/useStakeContract';
import useTimeUntil from 'utils/useTimeUntil';
import { notifyError, notifyLoading, notifySuccess } from 'utils/notifications';

dayjs.extend(duration);

const LabelButton = (props: any) => (
  <button
    type="button"
    className="flex px-2 py-1 text-sm font-bold uppercase rounded bg-purple-darker text-purple"
    {...props}
  >
    Max
  </button>
);

const lklandOptions = [
  {
    label: 'LKLAND-6cf78e',
    value: 'LKLAND-6cf78e',
    checked: true,
  },
  {
    label: 'LKLAND-c617f7',
    value: 'LKLAND-c617f7',
    checked: false,
  },
  {
    label: 'LKLAND (Presale 1)',
    value: LKTOKEN_META_ID,
    checked: false,
  },
  {
    label: 'LKLAND (Presale 2)',
    value: LKTOKEN_SECOND_META_ID,
    checked: false,
  },
];

const Home = () => {
  const { address, account, ...rest } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();

  const isMobile = useMedia('(max-width: 768px)');

  const [searchParams] = useSearchParams();

  // const { timeLeft } = useTimeUntil(1648231200000);
  const { timeLeft, days: d, hours, minutes, seconds } = useTimeUntil(LAUNCH_TIME);

  const [stakedLandQuantity, setStakedLandQuantity] = useState('');
  const [showModal, setShowModal] = useAtom(showModalAtom);
  const [referralCode, setReferralCode] = useState('');
  const [selectedToken, setSelectedToken] = useState(TOKEN_ID);
  const [lklandTypeId, setLklandType] = useState<any>('LKLAND-6cf78e');
  const [stakeTypes, setStakeTypes] = useState<any[]>([]);
  const [loaded, setLoaded] = useState({ balance: false, stake_types: false });
  const [totalLandBalance, setTotalLandBalance] = useState(0);
  const [totalLkLandBalance, setTotalLkLandBalance] = useState<any>({
    'LKLAND-6cf78e': 0,
    'LKLAND-c617f7': 0,
    LKTOKEN_META_ID: 0,
  });
  const [metaBalance, setMetaBalance] = useAtom(metaBalanceAtom);
  const [secondMetaBalance, setSecondMetaBalance] = useState([]);
  const [activeDay, setActiveDay] = useState(15);
  const [max, setMax] = useState(0);

  const [balance, setBalance] = useState(0);
  const [hasShownPending, setHasShownPending] = useState(false);
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);

  const { stakeContract } = useStakeContract(selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId);

  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      notifySuccess('Transaction successful');
    },
    onFail: () => {
      notifyError('Transaction failed');
    },
  });

  // useEffect(() => {
  //   setTransactionStatus(transactionStatus);
  // }, []);

  useEffect(() => {
    if (!hasShownPending && transactionStatus.isPending && transactionStatus.transactions) {
      notifyLoading(
        'Transaction pending',
        // @ts-ignore
        transactionStatus.transactions[0].hash,
      );
      setHasShownPending(true);
    }

    // @ts-ignore
    // console.log(transactionStatus.transactions[0].hash);
  }, [transactionStatus]);

  const handleChangeStakedQuantity = (e: any) => {
    const regex = RegExp(/[0-9]+/g);
    const test_result = regex.test(e.target.value);
    let newStakedQuantity = '';

    if (test_result) {
      const value = parseInt(e.target.value);
      if (value > 0) {
        newStakedQuantity = e.target.value;
        setStakedLandQuantity(newStakedQuantity);
      }
    }
    if (e.target.value === '') {
      setStakedLandQuantity('');
    }
  };

  const handleChangeReferralCode = (e: any) => {
    setReferralCode(e.target.value);
  };

  const handleStake = async () => {
    const balance =
      selectedToken === TOKEN_ID
        ? totalLandBalance
        : selectedToken !== LKTOKEN_META_ID
        ? totalLkLandBalance[lklandTypeId]
        : metaBalance;

    const tokenId = selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId;
    const selectedPlan = plans.filter((plan: any) => plan.days.valueOf() === activeDay)[0];

    let referralAddress = referralCode.length > 0 ? referralCode : undefined;

    if (referralAddress && !addressIsValid(referralAddress)) {
      const res = await fetch(`https://api.elrond.com/usernames/${referralCode.split('.')[0]}`);
      const data = await res.json();
      referralAddress = data.address;
    }

    if (account.address === referralAddress) {
      toast.error('Nice try but you cannot refer yourself');
      return;
    }

    const isMeta = lklandTypeId == LKTOKEN_META_ID || lklandTypeId == LKTOKEN_SECOND_META_ID;

    const nonce =
      tokenId !== LKTOKEN_SECOND_META_ID
        ? metaBalance.length > 0
          ? metaBalance.reduce((prev, current) =>
              Number(prev['balance']) > Number(current['balance']) ? prev : current,
            )['nonce']
          : 1
        : secondMetaBalance.length > 0
        ? secondMetaBalance.reduce((prev, current) =>
            Number(prev['balance']) > Number(current['balance']) ? prev : current,
          )['nonce']
        : 1;

    let sessionId = !isMeta
      ? await stakeContract?.createStakeTransaction(
          tokenId,
          selectedPlan.id,
          parseInt(stakedLandQuantity),
          false,
          1,
          address,
          referralAddress,
        )
      : await stakeContract?.createStakeTransaction(
          tokenId,
          selectedPlan.id,
          parseInt(stakedLandQuantity),
          true,
          nonce,
          address,
          referralAddress,
        );

    setSessionId(sessionId);
  };

  const handleSwitchToken = (token: string) => setSelectedToken(token);

  useEffect(() => {
    setReferralCode(searchParams.get('referral') ?? '');
  }, [searchParams]);

  useEffect(() => {
    getBalance(address).then(res => {
      setSecondMetaBalance(
        res.metaBalance.filter((a: any) => a.collection == LKTOKEN_SECOND_META_ID),
      );
      setMetaBalance(res.metaBalance.filter((a: any) => a.collection == LKTOKEN_META_ID));
    });
  }, []);

  useEffect(() => {
    if (account.address != '' && !loaded.balance) {
      setLoaded({ ...loaded, balance: true });
      axios
        .get(`${network.apiAddress}/accounts/${account.address}/tokens?size=100`)
        .then((res: any) => {
          if (res.data?.length > 0) {
            const tokens = res.data.filter(
              (a: any) => a?.identifier === TOKEN_ID || a?.ticker === TOKEN_ID,
            );
            const lkLand1 = res.data.filter(
              (a: any) => a?.identifier === LKTOKEN_ID1 || a?.ticker === LKTOKEN_ID1,
            );
            const lkLand2 = res.data.filter(
              (a: any) => a?.identifier === LKTOKEN_ID2 || a?.ticker === LKTOKEN_ID2,
            );
            setTotalLandBalance(tokens.length > 0 ? Math.floor(tokens[0]?.balance / 10 ** 18) : 0);
            setTotalLkLandBalance({
              'LKLAND-6cf78e': lkLand1.length > 0 ? Math.floor(lkLand1[0]?.balance / 10 ** 18) : 0,
              'LKLAND-c617f7': lkLand2.length > 0 ? Math.floor(lkLand2[0]?.balance / 10 ** 18) : 0,
              'LKLAND-516da7': 10,
            });
          }
        });
    }
  }, [account]);

  useEffect(() => {
    if (stakeContract) {
      stakeContract.getStakeTypes().then((stakeTypes: any) => {
        console.log('stakeTypes', stakeTypes);
        console.log(
          'stakeTypes',
          stakeTypes.items
            .filter((type: any) => type.fields[6].value.value.valueOf() == false)
            .map((item: any) => item.fields[0].value.value.valueOf()),
        );
        setStakeTypes(stakeTypes.items.filter((stakeType: any) => !stakeType.valueOf().disabled));
      });
    }
  }, [stakeContract, selectedToken]);

  const plans: any = useMemo(() => {
    return stakeTypes.length > 0
      ? stakeTypes
          .map(stakeType => {
            const stakeDays = dayjs
              .duration(stakeType.valueOf().locking_timestamp.valueOf(), 's')
              .asDays();
            const plans = landPlans.filter(landPlan => stakeDays == landPlan.days);
            const lklandPlans = lockedLandPlans.filter(landPlan => stakeDays == landPlan.days);

            if ((selectedToken === TOKEN_ID && plans.length > 0) || lklandPlans.length > 0) {
              return {
                ...plans[0],
                ...stakeType.valueOf(),
                id: stakeType.valueOf().stake_type_id.valueOf(),
                apr: stakeType.valueOf().apy.valueOf() / 100,
              };
            } else return null;
          })
          .filter(plan => plan != null)
      : (selectedToken === TOKEN_ID ? landPlans : lockedLandPlans).map(landPlan => ({
          ...landPlan,
          apr: landPlan.apr,
        }));
  }, [selectedToken, stakeTypes]);

  useEffect(() => {
    plans.map((a: any) => a.apr > max && setMax(a.apr));
  }, []);

  useEffect(() => {
    setBalance(
      selectedToken === TOKEN_ID
        ? totalLandBalance
        : lklandTypeId == LKTOKEN_META_ID
        ? metaBalance.length > 0
          ? Number(
              metaBalance.reduce((prev, current) =>
                Number(prev['balance']) > Number(current['balance']) ? prev : current,
              )['balance'] /
                10 ** 18,
            )
          : 0
        : lklandTypeId == LKTOKEN_SECOND_META_ID
        ? secondMetaBalance.length > 0
          ? Number(
              secondMetaBalance.reduce((prev, current) =>
                Number(prev['balance']) > Number(current['balance']) ? prev : current,
              )['balance'] /
                10 ** 18,
            )
          : 0
        : totalLkLandBalance[lklandTypeId],
    );
  }, [selectedToken, metaBalance, lklandTypeId]);

  // @ts-ignore

  const disabled = timeLeft > 0 || stakedLandQuantity === '0' || !stakedLandQuantity || !address;

  const handleSelectLkLand = (options: any[]) => {
    setLklandType(options.filter((a: any) => a.checked)[0].value);
  };

  return (
    <motion.div className="home" {...motionContainerProps}>
      <div className="stake-container">
        <div className="home__title">
          <motion.h1 variants={fadeInVariants}>
            EARN - STAKE YOUR <span className="text-purple">{selectedToken.split('-')[0]}</span>
          </motion.h1>
        </div>
        <TokenPicker
          token={selectedToken}
          tokens={[TOKEN_ID, 'LKLAND']}
          onClick={handleSwitchToken}
        />
        <motion.div className="home__form" onSubmit={() => {}} {...motionContainerProps}>
          <Input
            placeholder="0"
            label="Amount to Stake"
            value={stakedLandQuantity}
            onChange={handleChangeStakedQuantity}
            LabelButton={
              <LabelButton
                onClick={() =>
                  handleChangeStakedQuantity({
                    target: {
                      value: balance,
                    },
                  })
                }
              />
            }
          />
          <AnimatePresence>
            {address && (
              <motion.p variants={fadeInVariants} className="home__form--balance">
                {selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId} Balance:{' '}
                <span>{balance}</span>
              </motion.p>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {selectedToken === TOKEN_ID && (
              <motion.span
                className="text-sm text-purple"
                {...motionContainerProps}
                variants={fadeInVariants}
              >
                Stake at least 300 LAND using a referral code and get 2% bonus APR. <br /> It can
                only be used once and cannot be yourself.
              </motion.span>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {selectedToken === TOKEN_ID && (
              <Input
                placeholder="TOTHEMOON"
                label="Referral (herotag or address)"
                value={referralCode}
                onChange={handleChangeReferralCode}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {selectedToken !== TOKEN_ID && (
              <CheckboxGroup
                label="Tokens"
                options={lklandOptions}
                onChange={handleSelectLkLand}
                single
              />
            )}
          </AnimatePresence>
          <motion.div variants={fadeInVariants} className="home__form--info">
            <Icon name="info" primary />
            {balance > 0 ? (
              <span>
                There will be a 5 days unbonding time and 30% penalty on rewards for withdrawing
                before the chosen timestamp ends.
              </span>
            ) : (
              <span>Looks like you do not own any {selectedToken}.</span>
            )}
          </motion.div>
          {!isMobile && (
            <Button
              className="filled"
              onClick={handleStake}
              disabled={disabled}
              hideComingSoon={selectedToken === TOKEN_ID}
              animate
            >
              {timeLeft > 0
                ? 'Stake in ' + d + ' : ' + hours + ' : ' + minutes + ' : ' + seconds
                : 'STAKE'}
            </Button>
          )}
        </motion.div>
      </div>

      <motion.div className="home__title">
        <motion.h1 variants={fadeInVariants}>
          CHOOSE YOUR <span className="text-purple">PLAN</span>
        </motion.h1>
        <motion.p variants={fadeInVariants}>Stake your tokens and earn passive income.</motion.p>
        <motion.span className="mb-2 text-sm" variants={fadeInVariants}>
          Check staking economics{' '}
          <a
            className="underline text-purple"
            href="https://twitter.com/landboard_io/status/1505272042114924547"
          >
            here.
          </a>
        </motion.span>
        <div className="plan-grid">
          <motion.div
            className="plan-grid__content"
            drag={isMobile ? 'x' : false}
            dragConstraints={{ left: -800, right: 20 }}
          >
            <AnimatePresence>
              {plans.map((plan: any) => (
                <PlanCard
                  {...plan}
                  key={plan.title}
                  boostedUntil={
                    plan.title === 'Cyborg' || plan.title === 'Wizard' ? 1656856800000 : ''
                  }
                  boostedAmount={null}
                  isActive={activeDay === plan.days}
                  Icon={<Icon name={plan.title.toLowerCase()} primary />}
                  handleSelect={() => setActiveDay(plan.days)}
                />
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
        {isMobile && (
          <Button
            className="filled"
            containerClassname="mobile-size"
            onClick={handleStake}
            disabled={disabled}
            hideComingSoon={selectedToken === TOKEN_ID}
            animate
          >
            {timeLeft > 0
              ? 'Stake in ' + d + ' : ' + hours + ' : ' + minutes + ' : ' + seconds
              : 'STAKE'}
          </Button>
        )}
      </motion.div>
      <AnimatePresence>
        {showModal && (
          <UnlockTokensModal
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Home;
